function GrantsSupportSquad() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21512"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g mask="url(#mask0_8619_21512)">
        <circle cx="16" cy="16" r="15" stroke="#44B600" strokeWidth="2"></circle>
        <path stroke="#44B600" strokeWidth="2" d="M4 27L28 27"></path>
        <path stroke="#44B600" strokeWidth="2" d="M-1 23L33 23"></path>
        <path stroke="#44B600" strokeWidth="2" d="M-1 19L33 19"></path>
        <mask
          id="mask1_8619_21512"
          style={{ maskType: 'alpha' }}
          width="19"
          height="13"
          x="7"
          y="7"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M7 7H26V20H7z"></path>
        </mask>
        <g mask="url(#mask1_8619_21512)">
          <circle cx="16" cy="15" r="1" fill="#44B600"></circle>
        </g>
        <circle cx="16" cy="8" r="3" stroke="#44B600" strokeWidth="2"></circle>
        <circle cx="16" cy="16" r="15" stroke="#44B600" strokeWidth="2"></circle>
        <path fill="#fff" stroke="#44B600" strokeWidth="2" d="M13.586 23H-1.586L6 15.414 13.586 23z"></path>
        <path stroke="#44B600" strokeWidth="2" d="M6 16L6 23"></path>
        <path fill="#fff" stroke="#44B600" strokeWidth="2" d="M37.586 27H12.414L25 14.414 37.586 27z"></path>
        <path stroke="#44B600" strokeWidth="2" d="M25 15L25 28"></path>
        <circle cx="16" cy="16" r="15" stroke="#44B600" strokeWidth="2"></circle>
      </g>
    </svg>
  )
}

export default GrantsSupportSquad
