function SecurityAdvisoryBoard() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21388"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g mask="url(#mask0_8619_21388)">
        <circle cx="16" cy="16" r="15" stroke="#1768E9" strokeWidth="2"></circle>
        <path stroke="#1768E9" strokeWidth="2" d="M4 27L28 27"></path>
        <path stroke="#1768E9" strokeWidth="2" d="M-1 23L33 23"></path>
        <mask
          id="mask1_8619_21388"
          style={{ maskType: 'alpha' }}
          width="17"
          height="14"
          x="7"
          y="7"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M7 7H24V21H7z"></path>
        </mask>
        <g mask="url(#mask1_8619_21388)">
          <circle cx="16" cy="16" r="5" stroke="#1768E9" strokeWidth="2"></circle>
        </g>
        <circle cx="16" cy="6" r="1" stroke="#1768E9" strokeWidth="2"></circle>
        <circle cx="16" cy="16" r="15" stroke="#1768E9" strokeWidth="2"></circle>
        <path stroke="#1768E9" strokeWidth="2" d="M24.5 15.381l8 7.619h-16l8-7.619z"></path>
        <path stroke="#1768E9" strokeWidth="2" d="M24.5 16L24.5 24"></path>
        <path fill="#fff" stroke="#1768E9" strokeWidth="2" d="M22.586 27H-6.586L8 12.414 22.586 27z"></path>
        <path stroke="#1768E9" strokeWidth="2" d="M8 13L8 26"></path>
        <circle cx="16" cy="16" r="15" stroke="#1768E9" strokeWidth="2"></circle>
      </g>
    </svg>
  )
}

export default SecurityAdvisoryBoard
