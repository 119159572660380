function DclLogo({ size = 60 }: { size?: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
        fill="url(#paint0_linear_8618_38511)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2578 19.5V42H40.0078L21.2578 19.5Z"
        fill="url(#paint1_linear_8618_38511)"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.50781 42H21.2578V19.5L2.50781 42Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 54C17.01 57.765 23.25 60 30 60C36.75 60 42.99 57.765 48 54H12Z"
        fill="#FF2D55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99854 48.0002C7.70854 50.2652 9.73354 52.2902 11.9985 54.0002H47.9985C50.2635 52.2902 52.2885 50.2652 53.9985 48.0002H5.99854Z"
        fill="#FC9965"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2449 41.9998H2.50488C3.43488 44.1448 4.61988 46.1548 5.99988 47.9998H40.2599V41.9998H40.2449Z"
        fill="#FFBC5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2417 31.5V48H53.9967L40.2417 31.5Z"
        fill="url(#paint2_linear_8618_38511)"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5029 48H40.2429V31.5L26.5029 48Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2451 26.9998C44.3873 26.9998 47.7451 23.6419 47.7451 19.4998C47.7451 15.3576 44.3873 11.9998 40.2451 11.9998C36.103 11.9998 32.7451 15.3576 32.7451 19.4998C32.7451 23.6419 36.103 26.9998 40.2451 26.9998Z"
        fill="#FFC95B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2549 15C23.326 15 25.0049 13.3211 25.0049 11.25C25.0049 9.17893 23.326 7.5 21.2549 7.5C19.1838 7.5 17.5049 9.17893 17.5049 11.25C17.5049 13.3211 19.1838 15 21.2549 15Z"
        fill="#FFC95B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8618_38511"
          x1="30"
          y1="-12.4264"
          x2="-12.4264"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2D55" />
          <stop offset="1" stopColor="#FFBC5B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8618_38511"
          x1="21.2515"
          y1="19.5"
          x2="21.2515"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A524B3" />
          <stop offset="1" stopColor="#FF2D55" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8618_38511"
          x1="40.2371"
          y1="31.5"
          x2="40.2371"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A524B3" />
          <stop offset="1" stopColor="#FF2D55" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DclLogo
