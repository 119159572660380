import './CommitteesCoreUnitsBackground.css'

function CommitteesCoreUnitsBackground() {
  return (
    <svg
      className="CommitteesCoreUnitsBackground"
      xmlns="http://www.w3.org/2000/svg"
      width="628"
      height="282"
      fill="none"
      viewBox="0 0 628 282"
    >
      <g stroke="#E3E1E2" mask="url(#mask0_8618_38585)">
        <path d="M27 1H47V21H27z"></path>
        <path d="M47 1H67V21H47z"></path>
        <path d="M87 1H107V21H87z"></path>
        <path d="M127 1H147V21H127z"></path>
        <path d="M147 1H167V21H147z"></path>
        <path d="M167 1H187V21H167z"></path>
        <path d="M207 1H227V21H207z"></path>
        <path d="M247 1H267V21H247z"></path>
        <path d="M267 1H287V21H267z"></path>
        <path d="M307 1H327V21H307z"></path>
        <path d="M327 1H347V21H327z"></path>
        <path d="M367 1H387V21H367z"></path>
        <path d="M407 1H427V21H407z"></path>
        <path d="M427 1H447V21H427z"></path>
        <path d="M467 1H487V21H467z"></path>
        <path d="M527 1H547V21H527z"></path>
        <path d="M47 21H67V41H47z"></path>
        <path d="M67 21H87V41H67z"></path>
        <path d="M87 21H107V41H87z"></path>
        <path d="M107 21H127V41H107z"></path>
        <path d="M127 21H147V41H127z"></path>
        <path d="M147 21H167V41H147z"></path>
        <path d="M187 21H207V41H187z"></path>
        <path d="M207 21H227V41H207z"></path>
        <path d="M227 21H247V41H227z"></path>
        <path d="M267 21H287V41H267z"></path>
        <path d="M287 21H307V41H287z"></path>
        <path d="M327 21H347V41H327z"></path>
        <path d="M347 21H367V41H347z"></path>
        <path d="M367 21H387V41H367z"></path>
        <path d="M387 21H407V41H387z"></path>
        <path d="M427 21H447V41H427z"></path>
        <path d="M447 21H467V41H447z"></path>
        <path d="M467 21H487V41H467z"></path>
        <path d="M507 21H527V41H507z"></path>
        <path d="M547 21H567V41H547z"></path>
        <path d="M607 21H627V41H607z"></path>
        <path d="M27 41H47V61H27z"></path>
        <path d="M67 41H87V61H67z"></path>
        <path d="M87 41H107V61H87z"></path>
        <path d="M147 41H167V61H147z"></path>
        <path d="M167 41H187V61H167z"></path>
        <path d="M187 41H207V61H187z"></path>
        <path d="M207 41H227V61H207z"></path>
        <path d="M247 41H267V61H247z"></path>
        <path d="M267 41H287V61H267z"></path>
        <path d="M287 41H307V61H287z"></path>
        <path d="M307 41H327V61H307z"></path>
        <path d="M327 41H347V61H327z"></path>
        <path d="M347 41H367V61H347z"></path>
        <path d="M387 41H407V61H387z"></path>
        <path d="M407 41H427V61H407z"></path>
        <path d="M427 41H447V61H427z"></path>
        <path d="M467 41H487V61H467z"></path>
        <path d="M487 41H507V61H487z"></path>
        <path d="M507 41H527V61H507z"></path>
        <path d="M527 41H547V61H527z"></path>
        <path d="M547 41H567V61H547z"></path>
        <path d="M567 41H587V61H567z"></path>
        <path d="M587 41H607V61H587z"></path>
        <path d="M607 41H627V61H607z"></path>
        <path d="M27 61H47V81H27z"></path>
        <path d="M47 61H67V81H47z"></path>
        <path d="M67 61H87V81H67z"></path>
        <path d="M87 61H107V81H87z"></path>
        <path d="M107 61H127V81H107z"></path>
        <path d="M127 61H147V81H127z"></path>
        <path d="M147 61H167V81H147z"></path>
        <path d="M167 61H187V81H167z"></path>
        <path d="M207 61H227V81H207z"></path>
        <path d="M227 61H247V81H227z"></path>
        <path d="M267 61H287V81H267z"></path>
        <path d="M287 61H307V81H287z"></path>
        <path d="M327 61H347V81H327z"></path>
        <path d="M367 61H387V81H367z"></path>
        <path d="M387 61H407V81H387z"></path>
        <path d="M407 61H427V81H407z"></path>
        <path d="M427 61H447V81H427z"></path>
        <path d="M447 61H467V81H447z"></path>
        <path d="M467 61H487V81H467z"></path>
        <path d="M487 61H507V81H487z"></path>
        <path d="M507 61H527V81H507z"></path>
        <path d="M527 61H547V81H527z"></path>
        <path d="M547 61H567V81H547z"></path>
        <path d="M567 61H587V81H567z"></path>
        <path d="M587 61H607V81H587z"></path>
        <path d="M27 81H47V101H27z"></path>
        <path d="M67 81H87V101H67z"></path>
        <path d="M87 81H107V101H87z"></path>
        <path d="M107 81H127V101H107z"></path>
        <path d="M147 81H167V101H147z"></path>
        <path d="M167 81H187V101H167z"></path>
        <path d="M187 81H207V101H187z"></path>
        <path d="M207 81H227V101H207z"></path>
        <path d="M227 81H247V101H227z"></path>
        <path d="M247 81H267V101H247z"></path>
        <path d="M267 81H287V101H267z"></path>
        <path d="M287 81H307V101H287z"></path>
        <path d="M307 81H327V101H307z"></path>
        <path d="M327 81H347V101H327z"></path>
        <path d="M347 81H367V101H347z"></path>
        <path d="M367 81H387V101H367z"></path>
        <path d="M407 81H427V101H407z"></path>
        <path d="M427 81H447V101H427z"></path>
        <path d="M467 81H487V101H467z"></path>
        <path d="M487 81H507V101H487z"></path>
        <path d="M507 81H527V101H507z"></path>
        <path d="M547 81H567V101H547z"></path>
        <path d="M567 81H587V101H567z"></path>
        <path d="M587 81H607V101H587z"></path>
        <path d="M607 81H627V101H607z"></path>
        <path d="M27 101H47V121H27z"></path>
        <path d="M47 101H67V121H47z"></path>
        <path d="M67 101H87V121H67z"></path>
        <path d="M87 101H107V121H87z"></path>
        <path d="M107 101H127V121H107z"></path>
        <path d="M127 101H147V121H127z"></path>
        <path d="M147 101H167V121H147z"></path>
        <path d="M167 101H187V121H167z"></path>
        <path d="M187 101H207V121H187z"></path>
        <path d="M207 101H227V121H207z"></path>
        <path d="M227 101H247V121H227z"></path>
        <path d="M247 101H267V121H247z"></path>
        <path d="M267 101H287V121H267z"></path>
        <path d="M287 101H307V121H287z"></path>
        <path d="M307 101H327V121H307z"></path>
        <path d="M327 101H347V121H327z"></path>
        <path d="M347 101H367V121H347z"></path>
        <path d="M367 101H387V121H367z"></path>
        <path d="M387 101H407V121H387z"></path>
        <path d="M407 101H427V121H407z"></path>
        <path d="M427 101H447V121H427z"></path>
        <path d="M447 101H467V121H447z"></path>
        <path d="M467 101H487V121H467z"></path>
        <path d="M487 101H507V121H487z"></path>
        <path d="M507 101H527V121H507z"></path>
        <path d="M527 101H547V121H527z"></path>
        <path d="M547 101H567V121H547z"></path>
        <path d="M567 101H587V121H567z"></path>
        <path d="M587 101H607V121H587z"></path>
        <path d="M607 101H627V121H607z"></path>
        <path d="M27 121H47V141H27z"></path>
        <path d="M47 121H67V141H47z"></path>
        <path d="M67 121H87V141H67z"></path>
        <path d="M87 121H107V141H87z"></path>
        <path d="M107 121H127V141H107z"></path>
        <path d="M127 121H147V141H127z"></path>
        <path d="M147 121H167V141H147z"></path>
        <path d="M167 121H187V141H167z"></path>
        <path d="M187 121H207V141H187z"></path>
        <path d="M207 121H227V141H207z"></path>
        <path d="M227 121H247V141H227z"></path>
        <path d="M247 121H267V141H247z"></path>
        <path d="M267 121H287V141H267z"></path>
        <path d="M287 121H307V141H287z"></path>
        <path d="M307 121H327V141H307z"></path>
        <path d="M327 121H347V141H327z"></path>
        <path d="M347 121H367V141H347z"></path>
        <path d="M367 121H387V141H367z"></path>
        <path d="M387 121H407V141H387z"></path>
        <path d="M407 121H427V141H407z"></path>
        <path d="M427 121H447V141H427z"></path>
        <path d="M447 121H467V141H447z"></path>
        <path d="M467 121H487V141H467z"></path>
        <path d="M487 121H507V141H487z"></path>
        <path d="M507 121H527V141H507z"></path>
        <path d="M527 121H547V141H527z"></path>
        <path d="M547 121H567V141H547z"></path>
        <path d="M567 121H587V141H567z"></path>
        <path d="M587 121H607V141H587z"></path>
        <path d="M607 121H627V141H607z"></path>
        <path d="M27 141H47V161H27z"></path>
        <path d="M47 141H67V161H47z"></path>
        <path d="M67 141H87V161H67z"></path>
        <path d="M87 141H107V161H87z"></path>
        <path d="M107 141H127V161H107z"></path>
        <path d="M127 141H147V161H127z"></path>
        <path d="M147 141H167V161H147z"></path>
        <path d="M167 141H187V161H167z"></path>
        <path d="M187 141H207V161H187z"></path>
        <path d="M207 141H227V161H207z"></path>
        <path d="M227 141H247V161H227z"></path>
        <path d="M247 141H267V161H247z"></path>
        <path d="M267 141H287V161H267z"></path>
        <path d="M287 141H307V161H287z"></path>
        <path d="M307 141H327V161H307z"></path>
        <path d="M327 141H347V161H327z"></path>
        <path d="M347 141H367V161H347z"></path>
        <path d="M367 141H387V161H367z"></path>
        <path d="M387 141H407V161H387z"></path>
        <path d="M407 141H427V161H407z"></path>
        <path d="M427 141H447V161H427z"></path>
        <path d="M447 141H467V161H447z"></path>
        <path d="M467 141H487V161H467z"></path>
        <path d="M487 141H507V161H487z"></path>
        <path d="M507 141H527V161H507z"></path>
        <path d="M527 141H547V161H527z"></path>
        <path d="M547 141H567V161H547z"></path>
        <path d="M567 141H587V161H567z"></path>
        <path d="M587 141H607V161H587z"></path>
        <path d="M607 141H627V161H607z"></path>
        <path d="M27 161H47V181H27z"></path>
        <path d="M47 161H67V181H47z"></path>
        <path d="M67 161H87V181H67z"></path>
        <path d="M87 161H107V181H87z"></path>
        <path d="M107 161H127V181H107z"></path>
        <path d="M127 161H147V181H127z"></path>
        <path d="M147 161H167V181H147z"></path>
        <path d="M167 161H187V181H167z"></path>
        <path d="M187 161H207V181H187z"></path>
        <path d="M207 161H227V181H207z"></path>
        <path d="M227 161H247V181H227z"></path>
        <path d="M247 161H267V181H247z"></path>
        <path d="M267 161H287V181H267z"></path>
        <path d="M287 161H307V181H287z"></path>
        <path d="M307 161H327V181H307z"></path>
        <path d="M327 161H347V181H327z"></path>
        <path d="M347 161H367V181H347z"></path>
        <path d="M367 161H387V181H367z"></path>
        <path d="M387 161H407V181H387z"></path>
        <path d="M407 161H427V181H407z"></path>
        <path d="M427 161H447V181H427z"></path>
        <path d="M447 161H467V181H447z"></path>
        <path d="M467 161H487V181H467z"></path>
        <path d="M487 161H507V181H487z"></path>
        <path d="M507 161H527V181H507z"></path>
        <path d="M527 161H547V181H527z"></path>
        <path d="M547 161H567V181H547z"></path>
        <path d="M567 161H587V181H567z"></path>
        <path d="M587 161H607V181H587z"></path>
        <path d="M607 161H627V181H607z"></path>
        <path d="M27 181H47V201H27z"></path>
        <path d="M47 181H67V201H47z"></path>
        <path d="M67 181H87V201H67z"></path>
        <path d="M87 181H107V201H87z"></path>
        <path d="M107 181H127V201H107z"></path>
        <path d="M127 181H147V201H127z"></path>
        <path d="M147 181H167V201H147z"></path>
        <path d="M167 181H187V201H167z"></path>
        <path d="M187 181H207V201H187z"></path>
        <path d="M207 181H227V201H207z"></path>
        <path d="M227 181H247V201H227z"></path>
        <path d="M247 181H267V201H247z"></path>
        <path d="M267 181H287V201H267z"></path>
        <path d="M287 181H307V201H287z"></path>
        <path d="M307 181H327V201H307z"></path>
        <path d="M327 181H347V201H327z"></path>
        <path d="M347 181H367V201H347z"></path>
        <path d="M367 181H387V201H367z"></path>
        <path d="M387 181H407V201H387z"></path>
        <path d="M407 181H427V201H407z"></path>
        <path d="M427 181H447V201H427z"></path>
        <path d="M447 181H467V201H447z"></path>
        <path d="M467 181H487V201H467z"></path>
        <path d="M487 181H507V201H487z"></path>
        <path d="M507 181H527V201H507z"></path>
        <path d="M527 181H547V201H527z"></path>
        <path d="M547 181H567V201H547z"></path>
        <path d="M567 181H587V201H567z"></path>
        <path d="M587 181H607V201H587z"></path>
        <path d="M607 181H627V201H607z"></path>
        <path d="M27 201H47V221H27z"></path>
        <path d="M47 201H67V221H47z"></path>
        <path d="M67 201H87V221H67z"></path>
        <path d="M87 201H107V221H87z"></path>
        <path d="M107 201H127V221H107z"></path>
        <path d="M127 201H147V221H127z"></path>
        <path d="M147 201H167V221H147z"></path>
        <path d="M167 201H187V221H167z"></path>
        <path d="M187 201H207V221H187z"></path>
        <path d="M207 201H227V221H207z"></path>
        <path d="M227 201H247V221H227z"></path>
        <path d="M247 201H267V221H247z"></path>
        <path d="M267 201H287V221H267z"></path>
        <path d="M287 201H307V221H287z"></path>
        <path d="M307 201H327V221H307z"></path>
        <path d="M327 201H347V221H327z"></path>
        <path d="M347 201H367V221H347z"></path>
        <path d="M367 201H387V221H367z"></path>
        <path d="M387 201H407V221H387z"></path>
        <path d="M407 201H427V221H407z"></path>
        <path d="M427 201H447V221H427z"></path>
        <path d="M447 201H467V221H447z"></path>
        <path d="M467 201H487V221H467z"></path>
        <path d="M487 201H507V221H487z"></path>
        <path d="M507 201H527V221H507z"></path>
        <path d="M527 201H547V221H527z"></path>
        <path d="M547 201H567V221H547z"></path>
        <path d="M567 201H587V221H567z"></path>
        <path d="M587 201H607V221H587z"></path>
        <path d="M607 201H627V221H607z"></path>
        <path d="M27 221H47V241H27z"></path>
        <path d="M47 221H67V241H47z"></path>
        <path d="M67 221H87V241H67z"></path>
        <path d="M87 221H107V241H87z"></path>
        <path d="M107 221H127V241H107z"></path>
        <path d="M127 221H147V241H127z"></path>
        <path d="M147 221H167V241H147z"></path>
        <path d="M167 221H187V241H167z"></path>
        <path d="M187 221H207V241H187z"></path>
        <path d="M207 221H227V241H207z"></path>
        <path d="M227 221H247V241H227z"></path>
        <path d="M247 221H267V241H247z"></path>
        <path d="M267 221H287V241H267z"></path>
        <path d="M287 221H307V241H287z"></path>
        <path d="M307 221H327V241H307z"></path>
        <path d="M327 221H347V241H327z"></path>
        <path d="M347 221H367V241H347z"></path>
        <path d="M367 221H387V241H367z"></path>
        <path d="M387 221H407V241H387z"></path>
        <path d="M407 221H427V241H407z"></path>
        <path d="M427 221H447V241H427z"></path>
        <path d="M447 221H467V241H447z"></path>
        <path d="M467 221H487V241H467z"></path>
        <path d="M487 221H507V241H487z"></path>
        <path d="M507 221H527V241H507z"></path>
        <path d="M527 221H547V241H527z"></path>
        <path d="M547 221H567V241H547z"></path>
        <path d="M567 221H587V241H567z"></path>
        <path d="M587 221H607V241H587z"></path>
        <path d="M607 221H627V241H607z"></path>
        <path d="M27 241H47V261H27z"></path>
        <path d="M47 241H67V261H47z"></path>
        <path d="M67 241H87V261H67z"></path>
        <path d="M87 241H107V261H87z"></path>
        <path d="M107 241H127V261H107z"></path>
        <path d="M127 241H147V261H127z"></path>
        <path d="M147 241H167V261H147z"></path>
        <path d="M167 241H187V261H167z"></path>
        <path d="M187 241H207V261H187z"></path>
        <path d="M207 241H227V261H207z"></path>
        <path d="M227 241H247V261H227z"></path>
        <path d="M247 241H267V261H247z"></path>
        <path d="M267 241H287V261H267z"></path>
        <path d="M287 241H307V261H287z"></path>
        <path d="M307 241H327V261H307z"></path>
        <path d="M327 241H347V261H327z"></path>
        <path d="M347 241H367V261H347z"></path>
        <path d="M367 241H387V261H367z"></path>
        <path d="M387 241H407V261H387z"></path>
        <path d="M407 241H427V261H407z"></path>
        <path d="M427 241H447V261H427z"></path>
        <path d="M447 241H467V261H447z"></path>
        <path d="M467 241H487V261H467z"></path>
        <path d="M487 241H507V261H487z"></path>
        <path d="M507 241H527V261H507z"></path>
        <path d="M527 241H547V261H527z"></path>
        <path d="M547 241H567V261H547z"></path>
        <path d="M567 241H587V261H567z"></path>
        <path d="M587 241H607V261H587z"></path>
        <path d="M607 241H627V261H607z"></path>
        <path d="M27 261H47V281H27z"></path>
        <path d="M47 261H67V281H47z"></path>
        <path d="M67 261H87V281H67z"></path>
        <path d="M87 261H107V281H87z"></path>
        <path d="M107 261H127V281H107z"></path>
        <path d="M127 261H147V281H127z"></path>
        <path d="M147 261H167V281H147z"></path>
        <path d="M167 261H187V281H167z"></path>
        <path d="M187 261H207V281H187z"></path>
        <path d="M207 261H227V281H207z"></path>
        <path d="M227 261H247V281H227z"></path>
        <path d="M247 261H267V281H247z"></path>
        <path d="M267 261H287V281H267z"></path>
        <path d="M287 261H307V281H287z"></path>
        <path d="M307 261H327V281H307z"></path>
        <path d="M327 261H347V281H327z"></path>
        <path d="M347 261H367V281H347z"></path>
        <path d="M367 261H387V281H367z"></path>
        <path d="M387 261H407V281H387z"></path>
        <path d="M407 261H427V281H407z"></path>
        <path d="M427 261H447V281H427z"></path>
        <path d="M447 261H467V281H447z"></path>
        <path d="M467 261H487V281H467z"></path>
        <path d="M487 261H507V281H487z"></path>
        <path d="M507 261H527V281H507z"></path>
        <path d="M527 261H547V281H527z"></path>
        <path d="M547 261H567V281H547z"></path>
        <path d="M567 261H587V281H567z"></path>
        <path d="M587 261H607V281H587z"></path>
        <path d="M607 261H627V281H607z"></path>
      </g>
    </svg>
  )
}

export default CommitteesCoreUnitsBackground
