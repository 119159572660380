import { useQuery } from '@tanstack/react-query'

import { Governance } from '../clients/Governance'

function useVestingContractData(vestingAddresses: string[]) {
  const { data: vestingData, isLoading } = useQuery({
    queryKey: [`vesting-contract-data`, vestingAddresses],
    queryFn: async () => Governance.get().getVestings(vestingAddresses),
  })

  return { vestingData, isLoading }
}

export default useVestingContractData
