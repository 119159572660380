import { useState } from 'react'

import './VoteVPAvatarSelector.css'
import VoteVPAvatarSelectorItem from './VoteVPAvatarSelectorItem'
import VoteVPCarouselItem from './VoteVPCarouselItem'

export default function VoteVPAvatarSelector({ avatars }: { avatars: string[] }) {
  const [selectedAvatar, setSelectedAvatar] = useState<string>(avatars[0])

  return (
    <div className="VoteVPAvatarSelector">
      <div className="VoteVPAvatarSelector__SelectedItem">
        <VoteVPCarouselItem address={selectedAvatar} visible isSelected />
      </div>
      <div className="VoteVPAvatarSelector__Grid">
        {avatars.map((address) => (
          <VoteVPAvatarSelectorItem
            key={address}
            address={address}
            onClick={() => setSelectedAvatar(address)}
            isSelected={selectedAvatar === address}
          />
        ))}
      </div>
    </div>
  )
}
