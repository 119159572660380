import classNames from 'classnames'
import { Mobile } from 'decentraland-ui/dist/components/Media/Media'

import DAOCommittee from '../Icon/Badges/DAOCommittee'
import FacilitationSquad from '../Icon/Badges/FacilitationSquad'
import GovernanceSquad from '../Icon/Badges/GovernanceSquad'
import GrantsSupportSquad from '../Icon/Badges/GrantsSupportSquad'
import RevocationsCommittee from '../Icon/Badges/RevocationsCommittee'
import SecurityAdvisoryBoard from '../Icon/Badges/SecurityAdvisoryBoard'
import StrategicUnitSquad from '../Icon/Badges/StrategicUnitSquad'
import WearableCurationCommittee from '../Icon/Badges/WearableCurationCommittee'

import CommitteesCoreUnitsBackground from './CommitteesCoreUnitsBackground'
import ExternalInButton from './ExternalInButton'
import SectionParagraph from './SectionParagraph'
import SectionTitle from './SectionTitle'
import './WhatIsTheDAO.css'
import WhatIsTheDAOProposals from './WhatIsTheDAOProposals'

const SMART_CONTRACTS = [
  { name: 'LAND', description: 'Manages LAND tokens.', emoji: '⛰️' },
  { name: 'ESTATE', description: 'Manages grouped LAND parcels.', emoji: '🏘️' },
  { name: 'POIs', description: `Lists notable locations in Decentraland's Genesis City.`, emoji: '📍' },
  { name: 'Names', description: 'Mints unique NFT avatar names.', emoji: '🏷️' },
  { name: 'Banned names', description: 'Tracks banned NFT Names.', emoji: '🚫' },
  { name: 'Catalyst nodes', description: 'Tracks decentralized content servers.', emoji: '🌐' },
  { name: 'Wearables collections', description: 'Groups wearables for minting.', emoji: '👕' },
  { name: 'Marketplace contracts', description: 'Handles NFT sales, bids, and fees on the market.', emoji: '🛒' },
  { name: 'Grants', description: 'Manages vesting schedules for Grant payments.', emoji: '💰' },
]

const CORE_UNITS_AND_COMMITEES = [
  { name: 'DAO Committee', icon: DAOCommittee },
  { name: 'Security Advisory Board', icon: SecurityAdvisoryBoard },
  { name: 'Wearable Curation Committee', icon: WearableCurationCommittee },
  { name: 'Governance Squad', icon: GovernanceSquad },
  { name: 'Revocations Committee', icon: RevocationsCommittee },
  { name: 'Facilitation Squad', icon: FacilitationSquad },
  { name: 'Grants Support Squad', icon: GrantsSupportSquad },
  { name: 'Strategic Unit Squad', icon: StrategicUnitSquad },
]

export default function WhatIsTheDao() {
  return (
    <div className="Home__Card WhatIsTheDao">
      <div className="Home__CardContainer">
        <SectionTitle>What is the DAO?</SectionTitle>
        <SectionParagraph>
          This is our decision-making tool. We make important changes to the Decentraland ecosystem through{' '}
          <strong>proposals</strong>.
        </SectionParagraph>
        <WhatIsTheDAOProposals />
        <SectionParagraph>
          Our DAO controls Decentraland&apos;s critical <strong>smart contracts</strong>.{' '}
          <a
            href="https://docs.decentraland.org/player/general/dao/overview/what-smart-contracts-does-the-dao-control/"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about smart contracts.
          </a>
        </SectionParagraph>
        <div className="WhatIsTheDao__SmartContractGrid">
          {SMART_CONTRACTS.map(({ name, emoji, description }) => (
            <div key={name} className="WhatIsTheDao__SmartContractItem">
              <div className="WhatIsTheDao__SmartContractItemWrapper">
                <div
                  className={classNames('WhatIsTheDao__SmartContractItemBox', 'WhatIsTheDao__SmartContractItemFront')}
                >
                  <div>{emoji}</div>
                  <div className="WhatIsTheDao__SmartContractItemText">{name}</div>
                </div>
                <div
                  className={classNames('WhatIsTheDao__SmartContractItemBox', 'WhatIsTheDao__SmartContractItemBack')}
                >
                  <span>{description}</span>
                </div>
              </div>
            </div>
          ))}
          <Mobile>
            <a
              href="https://docs.decentraland.org/player/general/dao/overview/what-smart-contracts-does-the-dao-control/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="WhatIsTheDao__SmartContractItem">
                <div
                  className={classNames(
                    'WhatIsTheDao__SmartContractItemBox',
                    'WhatIsTheDao__SmartContractItemFront',
                    'WhatIsTheDao__LearnMoreGridItem'
                  )}
                >
                  <ExternalInButton size={20} />
                  <span>Learn more</span>
                </div>
              </div>
            </a>
          </Mobile>
        </div>
        <SectionParagraph className="WhatIsTheDao__CoreUnitCommitteeDescription">
          We have chosen a set of individuals to represent us, and to make sure the community runs smoothly. These
          individuals form the <strong>following committees and core units:</strong>
        </SectionParagraph>
      </div>
      <div className="WhatIsTheDao__CoreUnitCommitteeContainer">
        <CommitteesCoreUnitsBackground />
        <div className="WhatIsTheDao__CoreUnitCommitteeList">
          {CORE_UNITS_AND_COMMITEES.map(({ name, icon }) => {
            const IconComponent = icon

            return (
              <div key={name} className="WhatIsTheDao__CoreUnitCommitteeItem">
                <IconComponent />
                <span className="WhatIsTheDao__CoreUnitCommitteeName">{name}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
