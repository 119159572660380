function ExternalInButton({ size = 10 }: { size?: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 10 11">
      <path
        stroke="#FF2D55"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 5.917v2.5a.833.833 0 01-.833.833H2.083a.833.833 0 01-.833-.833V3.833A.833.833 0 012.083 3h2.5M6.25 1.75h2.5v2.5M4.167 6.333L8.75 1.75"
      ></path>
    </svg>
  )
}

export default ExternalInButton
