import Lottie from 'react-lottie-player'

import { useMobileMediaQuery } from 'decentraland-ui/dist/components/Media/Media'

import DclLogo from '../Icon/DclLogo.tsx'

import heroDesktop from './lotties/hero-desktop.json'
import heroMobile from './lotties/hero-mobile.json'

import './HeroBackground.css'

export function HeroBackground() {
  const isMobile = useMobileMediaQuery()

  return (
    <div className="HeroContainer">
      <div className="HeroBackground">
        {!isMobile && <Lottie animationData={heroDesktop} play />}
        {isMobile && <Lottie animationData={heroMobile} play />}
      </div>
      <div className="HeroBackground__LogoContainer">
        <DclLogo size={isMobile ? 40 : 60} />
      </div>
      <div className="HeroBackground__WhiteBlur" />
    </div>
  )
}
