import classNames from 'classnames'
import { useMobileMediaQuery } from 'decentraland-ui/dist/components/Media/Media'

import CheckIcon from './CheckIcon'
import Pill, { PillColor, Props as PillProps } from './Pill'
import { getEnumDisplayName } from './helpers'
import { ProposalStatus } from './types'

function getProposalStatusShortName(status: ProposalStatus) {
  return status === ProposalStatus.OutOfBudget ? 'OOB' : getEnumDisplayName(status)
}

type Props = {
  className?: string
  status: ProposalStatus
  size?: PillProps['size']
  color?: PillColor
}

const ColorsConfig: Record<ProposalStatus, PillColor> = {
  [ProposalStatus.Pending]: PillColor.Gray,
  [ProposalStatus.Active]: PillColor.Gray,
  [ProposalStatus.Finished]: PillColor.Gray,
  [ProposalStatus.Passed]: PillColor.Green,
  [ProposalStatus.Enacted]: PillColor.Green,
  [ProposalStatus.OutOfBudget]: PillColor.Yellow,
  [ProposalStatus.Rejected]: PillColor.Red,
  [ProposalStatus.Deleted]: PillColor.Red,
}

export default function StatusPill({ className, status, size, color }: Props) {
  const isMobile = useMobileMediaQuery()

  const style = status === (ProposalStatus.Enacted || ProposalStatus.OutOfBudget) ? 'shiny' : 'outline'
  const showIcon = status === ProposalStatus.Enacted || status === ProposalStatus.Passed
  const iconColor = status === ProposalStatus.Enacted ? 'var(--white-900)' : 'var(--green-800)'
  const icon = showIcon ? <CheckIcon color={iconColor} /> : null
  const name = isMobile ? getProposalStatusShortName(status) : getEnumDisplayName(status)
  const pillSize = isMobile ? 'sm' : size || 'md'

  return (
    <Pill
      size={pillSize}
      style={style}
      className={classNames('StatusPill', className)}
      color={color || ColorsConfig[status]}
      icon={icon}
    >
      {name}
    </Pill>
  )
}
