import classNames from 'classnames'
import { t } from 'decentraland-dapps/dist/modules/translation'
import { Button } from 'decentraland-ui'

import './Hero.css'
import { HeroBackground } from './HeroBackground'

export default function Hero() {
  return (
    <div className="Hero">
      <HeroBackground />
      <div className="Hero__Content">
        <h1 className="Hero__Title">
          {t('dao.landing.hero.title1')}
          <br />
          {t('dao.landing.hero.title2')}
        </h1>
        <h3 className="Hero__Subtitle">
          {t('dao.landing.hero.description1')}
          <br />
          {t('dao.landing.hero.description2')}
        </h3>
        <div className="Hero__Actions">
          <Button
            primary
            className="Hero__Button"
            href="https://decentraland.org/governance"
            target="_blank"
            rel="noreferrer"
          >
            {t('dao.landing.hero.action')}
          </Button>
          <Button
            className={classNames('Hero__Button', 'Hero__LearnMoreButton')}
            href="https://docs.decentraland.org/player/general/dao/overview/what-is-the-dao/"
            target="_blank"
            rel="noreferrer"
          >
            {t('dao.landing.hero.secondary_action')}
          </Button>
        </div>
      </div>
    </div>
  )
}
