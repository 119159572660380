import classNames from 'classnames'

import useDclProfile from '../../hooks/useDclProfile'

import './VoteVPAvatarSelectorItem.css'

interface Props {
  address: string
  onClick: () => void
  isSelected: boolean
}

export default function VoteVPAvatarSelectorItem({ address, onClick, isSelected }: Props) {
  const { profile } = useDclProfile(address)

  return (
    <button
      className={classNames('VoteVPAvatarSelectorItem', isSelected && 'VoteVPAvatarSelectorItem--selected')}
      onClick={onClick}
    >
      {profile && <img className="VoteVPAvatarSelectorItem__Image" src={profile.avatarUrl} />}
    </button>
  )
}
