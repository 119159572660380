export function toDaoPathname(pathname: string) {
  if (location.pathname.indexOf('/dao') === 0) {
    return `/dao${pathname}`
  }

  return pathname
}

export function isMetaClick(event: React.MouseEvent<HTMLAnchorElement>) {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
}

export function isRelativeLink(href?: string | null) {
  return (
    typeof href === 'string' && !href.startsWith('https://') && !href.startsWith('http://') && !href.startsWith('//')
  )
}
