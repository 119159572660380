import { useEffect, useState } from 'react'
import useMeasure from 'react-use-measure'

import { animate, motion, useMotionValue } from 'framer-motion'

import ProposalPreviewCard from './ProposalPreviewCard/ProposalPreviewCard'
import { ProposalAttributes } from './ProposalPreviewCard/types'

interface Props {
  className: string
  invertAnimation?: boolean
  proposals: ProposalAttributes[]
}

const SLOW_DURATION = 3000
const FAST_DURATION = 100

export default function AnimatedProposalsRow({ className, invertAnimation, proposals }: Props) {
  const [ref, { width }] = useMeasure()
  const initialPosition = invertAnimation ? -width / 2 - 8 : 0
  const xTranslation = useMotionValue(initialPosition)
  const [duration, setDuration] = useState(FAST_DURATION)
  const [mustFinish, setMustFinish] = useState(false)
  const [rerender, setRerender] = useState(false)

  useEffect(() => {
    const finalPosition = invertAnimation ? -8 : -width / 2 - 8
    let controls

    const animateCarousel = (from: number, to: number, dur: number, repeat: boolean) => {
      return animate(xTranslation, [from, to], {
        ease: 'linear',
        duration: dur,
        repeat: repeat ? Infinity : 0,
        repeatType: 'loop',
        repeatDelay: 0,
        onComplete: () => {
          if (mustFinish) {
            setMustFinish(false)
            setRerender(!rerender)
          }
        },
      })
    }

    if (mustFinish) {
      const currentPosition = xTranslation.get()
      const remainingDistance = invertAnimation ? Math.abs(currentPosition) : Math.abs(finalPosition - currentPosition)
      const remainingDuration = (duration * remainingDistance) / Math.abs(finalPosition - initialPosition)
      controls = animateCarousel(currentPosition, finalPosition, remainingDuration, false)
    } else {
      controls = animateCarousel(initialPosition, finalPosition, duration, true)
    }

    return controls.stop
  }, [duration, width, xTranslation, rerender, mustFinish, invertAnimation, initialPosition])

  return (
    <motion.div
      ref={ref}
      className={className}
      style={{ x: xTranslation, width: 'max-content' }}
      onHoverStart={() => {
        setMustFinish(true)
        setDuration(SLOW_DURATION)
      }}
      onHoverEnd={() => {
        setMustFinish(true)
        setDuration(FAST_DURATION)
      }}
    >
      {[...proposals, ...proposals].map((item, index) => (
        <ProposalPreviewCard key={`${item.id}-${index}`} proposal={item as unknown as ProposalAttributes} />
      ))}
    </motion.div>
  )
}
