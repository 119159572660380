import { t } from 'decentraland-dapps/dist/modules/translation'

import Head from '../components/Head'
import BigCTA from '../components/Home/BigCTA'
import Footer from '../components/Home/Footer'
import Hero from '../components/Home/Hero'
import HowDoesItWork from '../components/Home/HowDoesItWork'
import Treasury from '../components/Home/Treasury'
import VoteVP from '../components/Home/VoteVP'
import WhatIsTheDao from '../components/Home/WhatIsTheDAO'
import logo from '../images/decentraland.svg'

import './index.css'

export default function IndexPage() {
  return (
    <>
      <Head title={t('dao.meta.title')} description={t('dao.meta.description')} image={logo} />
      <Hero />
      <div className="Home__Container">
        <div className="Home__Wrapper">
          <WhatIsTheDao />
          <HowDoesItWork />
          <VoteVP />
          <Treasury />
        </div>
        <BigCTA />
        <Footer />
      </div>
    </>
  )
}
