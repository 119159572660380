function NewsletterIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <path
        stroke="#FF2D55"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 4.5V11a1.25 1.25 0 01-1.25 1.25m0 0A1.25 1.25 0 0111 11V2.25a.5.5 0 00-.5-.5H1a.5.5 0 00-.5.5v9a1 1 0 001 1h10.75z"
      ></path>
      <path stroke="#FF2D55" strokeLinecap="round" strokeLinejoin="round" d="M8 4.25H3.5v2.5H8v-2.5zM3.5 9.75H8"></path>
    </svg>
  )
}

export default NewsletterIcon
