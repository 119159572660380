/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'

import { formatNumber } from 'decentraland-dapps/dist/lib'
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

import { VpDistribution } from '../../clients/Governance'
import useVPDistribution from '../../hooks/useVPDistribution'

import './VPDistributionChart.css'

interface Props {
  address: string
}

const numberFormat = Intl.NumberFormat('en')

const EXCLUDED_KEYS: Set<keyof VpDistribution> = new Set(['total', 'own'])
const COLOR_MAP: Partial<Record<keyof VpDistribution, string>> = {
  mana: 'var(--dcl-primary)',
  wMana: 'var(--dcl-primary)',
  land: 'var(--green-800)',
  estate: 'var(--green-900)',
  names: 'var(--yellow-800)',
  delegated: 'var(--blue-800)',
  l1Wearables: 'var(--orange-800)',
  rental: 'var(--purple-800)',
}

function VPDistributionChart({ address }: Props) {
  const { vpDistribution } = useVPDistribution(address)
  const formatedData = useMemo(
    () =>
      Object.entries(vpDistribution)
        .map(([name, value]) => ({ name, value, total: vpDistribution.total }))
        .filter(({ name }) => !EXCLUDED_KEYS.has(name as keyof VpDistribution)),
    [vpDistribution]
  )

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const { name, value, total } = payload[0].payload.payload
      const percentage = ((value / total) * 100).toFixed(1)

      return (
        <div className="VPDistributionChart__Tooltip">
          <p className="VPDistributionChart__TooltipLabel">{name}</p>
          <div className="VPDistributionChart__TooltipValue">
            <span>{formatNumber(value)}</span>
            <span className="VPDistributionChart__TooltipValuePercentage">{`(${percentage}%)`}</span>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <>
      <ResponsiveContainer className="VPDistributionChart" width="100%" height="100%">
        <PieChart>
          <Pie
            width={800}
            height={800}
            data={formatedData}
            cx={120}
            cy={200}
            innerRadius={70}
            outerRadius={85}
            paddingAngle={5}
            dataKey="value"
            cornerRadius={3}
          >
            {formatedData.map(({ name }, index) => (
              <Cell key={`cell-${index}`} fill={COLOR_MAP[name as keyof VpDistribution]} />
            ))}
            <Label
              className="VPDistributionChart__TotalLabel"
              value={formatNumber(vpDistribution.total)}
              position="center"
              dy={-5}
            />
            <Label className="VPDistributionChart__VPLabel" value={`VP`} dy={15} position="center" />
          </Pie>
          <Tooltip content={CustomTooltip} />
        </PieChart>
      </ResponsiveContainer>
      <div className="VPDistributionChart__Totals">
        <div className="VPDistributionChart__TotalsItem">
          <span className="VPDistributionChart__TotalsItemCategory">Own</span>
          <span className="VPDistributionChart__TotalsItemValue">{numberFormat.format(vpDistribution?.own)}</span>
        </div>
        <div className="VPDistributionChart__TotalsItem">
          <span className="VPDistributionChart__TotalsItemCategory">Delegated</span>
          <span className="VPDistributionChart__TotalsItemValue">{numberFormat.format(vpDistribution?.delegated)}</span>
        </div>
      </div>
    </>
  )
}

export default VPDistributionChart
