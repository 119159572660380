import React, { useState } from 'react'

import { Governance } from '../../clients/Governance'
import { IconOk } from '../Icon'
import Link from '../Typography/Link'

import './GrantsFooter.css'

export default function GrantsFooter() {
  const [email, setEmail] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    Governance.get()
      .subscribeToNewsletter(email)
      .then((res) => !res.error && setShowConfirmation(true))
  }

  const ConfirmSnackBar = () => {
    if (showConfirmation) {
      setTimeout(() => setShowConfirmation(false), 5000)
      return (
        <div className="sentMessage__badge">
          <IconOk />
          Please check your email to confirm your subscription to our newsletter.
        </div>
      )
    }
    return null
  }

  return (
    <div className="footer__container">
      <div className="footer__leftPanel">
        <div className="footer__logo" />
        <div className="footer__links">
          <Link className="footer__link" href="/">
            DAO Home
          </Link>
          <a className="footer__link" target="_blank" rel="noreferrer" href="https://dcl.gg/dao-discord">
            Discord
          </a>
          <a className="footer__link" target="_blank" rel="noreferrer" href="https://www.youtube.com/@decentralanddao">
            Youtube
          </a>
          <a className="footer__link" target="_blank" rel="noreferrer" href="mailto://palewin@decentraland.org">
            <b>Contact Grant Support Squad</b>
          </a>
        </div>
      </div>
      <div className="footer__rightPanel">
        <div className="footer__link">
          <b>Subscribe to the DAO Newsletter</b>
        </div>
        <form className="subscribe--wrapper" onSubmit={handleSubmit}>
          <input
            className="subscribe--input"
            required
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            value={email}
            placeholder="Email"
          />
          <span className="subscribe--btn" onClick={handleSubmit}>
            SUBSCRIBE
          </span>
        </form>
      </div>
      <ConfirmSnackBar />
    </div>
  )
}
