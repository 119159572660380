import classNames from 'classnames'
import { useMobileMediaQuery } from 'decentraland-ui/dist/components/Media/Media'

import { MOCK_PROPOSALS } from './ProposalPreviewCard/helpers'
import { ProposalAttributes } from './ProposalPreviewCard/types'

import AnimatedProposalsRow from './AnimatedProposalsRow'
import './WhatIsTheDAOProposals.css'

function splitArray<T>(array: T[], numParts: number): T[][] {
  const length = array.length
  const baseSize = Math.floor(length / numParts)
  const remainder = length % numParts

  const result: T[][] = []
  let startIndex = 0

  for (let i = 0; i < numParts; i++) {
    const size = baseSize + (i < remainder ? 1 : 0)
    if (i === numParts - 1) {
      // Ensure the last part gets all remaining elements
      result.push(array.slice(startIndex))
    } else {
      result.push(array.slice(startIndex, startIndex + size))
      startIndex += size
    }
  }

  return result
}

export default function WhatIsTheDAOProposals() {
  const desktopProposals = splitArray(MOCK_PROPOSALS as unknown as ProposalAttributes[], 3)
  const mobileProposals = splitArray(MOCK_PROPOSALS as unknown as ProposalAttributes[], 2)
  const isMobile = useMobileMediaQuery()

  return (
    <div className="WhatIsTheDaoProposals">
      {!isMobile && (
        <>
          <AnimatedProposalsRow
            proposals={desktopProposals[0]}
            className={classNames('WhatIsTheDaoProposals__Container')}
          />
          <AnimatedProposalsRow
            proposals={desktopProposals[1]}
            className={classNames('WhatIsTheDaoProposals__Container', 'WhatIsTheDaoProposals__SecondRowContainer')}
            invertAnimation
          />
          <AnimatedProposalsRow
            proposals={desktopProposals[2]}
            className={classNames('WhatIsTheDaoProposals__Container', 'WhatIsTheDaoProposals__ThirdRowContainer')}
          />
        </>
      )}
      {isMobile && (
        <>
          <AnimatedProposalsRow
            proposals={mobileProposals[0]}
            className={classNames('WhatIsTheDaoProposals__Container')}
          />
          <AnimatedProposalsRow
            proposals={mobileProposals[1]}
            className={classNames('WhatIsTheDaoProposals__Container', 'WhatIsTheDaoProposals__SecondRowContainer')}
            invertAnimation
          />
        </>
      )}
    </div>
  )
}
