import classNames from 'classnames'
import { Desktop, Mobile, Tablet } from 'decentraland-ui/dist/components/Media/Media'

import BlogIcon from './BlogIcon'
import BugIcon from './BugIcon'
import DiscordIcon from './DiscordIcon'
import './Footer.css'
import FooterGridDesktop from './FooterGridDesktop'
import FooterGridMobile from './FooterGridMobile'
import FooterGridTablet from './FooterGridTablet'
import ForumIcon from './ForumIcon'
import NewsletterIcon from './NewsletterIcon'

export default function Footer() {
  return (
    <div className="HomeFooter">
      <Mobile>
        <FooterGridMobile />
      </Mobile>
      <Tablet>
        <FooterGridTablet />
      </Tablet>
      <Desktop>
        <FooterGridDesktop />
      </Desktop>
      <div className={classNames('HomeFooter__Element', 'HomeFooter__Description')}>
        There are many ways to get involved if you&apos;re not ready to submit a proposal
      </div>
      <a href="https://immunefi.com/bug-bounty/decentraland/" target="_blank" rel="noreferrer">
        <div className={classNames('HomeFooter__Element', 'HomeFooter__Button', 'HomeFooter__Bug')}>
          <BugIcon /> Submit a bug
        </div>
      </a>
      <a href="https://decentraland.org/blog/" target="_blank" rel="noreferrer">
        <div className={classNames('HomeFooter__Element', 'HomeFooter__Button', 'HomeFooter__Blog')}>
          <BlogIcon />
          Read the blog
        </div>
      </a>
      <a href="https://forum.decentraland.org/" target="_blank" rel="noreferrer">
        <div className={classNames('HomeFooter__Element', 'HomeFooter__Button', 'HomeFooter__Forum')}>
          <ForumIcon />
          Discuss in the forum
        </div>
      </a>
      <a href="https://dcl.gg/dao-discord" target="_blank" rel="noreferrer">
        <div className={classNames('HomeFooter__Element', 'HomeFooter__Button', 'HomeFooter__Discord')}>
          <DiscordIcon />
          Join Discord
        </div>
      </a>
      <a href="https://decentraland-dao.beehiiv.com/subscribe" target="_blank" rel="noreferrer">
        <div className={classNames('HomeFooter__Element', 'HomeFooter__Button', 'HomeFooter__Newsletter')}>
          <NewsletterIcon />
          Read the newsletter
        </div>
      </a>
    </div>
  )
}
