import classNames from 'classnames'

import './BigCTA.css'

export default function BigCTA() {
  return (
    <div className="BigCTA">
      <div className="BigCTA__TextHighlightContainer">
        <div className={classNames('BigCTA__Text', 'BigCTA__Text--highlight', 'BigCTA__Text--text1')}>Discuss.</div>
        <div className={classNames('BigCTA__Text', 'BigCTA__Text--highlight', 'BigCTA__Text--text2')}>Vote.</div>
        <div className={classNames('BigCTA__Text', 'BigCTA__Text--highlight', 'BigCTA__Text--text3')}>Read.</div>
      </div>
      <div className="BigCTA__Text">Decentraland is in your hands.</div>
    </div>
  )
}
