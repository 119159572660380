import { ProposalAttributes, ProposalStatus, ProposalType } from '../components/Home/ProposalPreviewCard/types'

import API, { ApiOptions } from './API'

export class Governance extends API {
  static Url = `https://governance.decentraland.org/api`

  static Cache = new Map<string, Governance>()

  static from(baseUrl: string) {
    if (!this.Cache.has(baseUrl)) {
      this.Cache.set(baseUrl, new this(baseUrl))
    }

    return this.Cache.get(baseUrl)!
  }

  static get() {
    return this.from(this.Url)
  }

  async fetchApiResponse<T>(endpoint: string, options: ApiOptions = { method: 'GET' }): Promise<T> {
    return (await this.fetch<ApiResponse<T>>(endpoint, options)).data
  }

  async subscribeToNewsletter(email: string) {
    return await this.fetchApiResponse<NewsletterSubscriptionResult>(`/newsletter-subscribe`, {
      method: 'POST',
      json: {
        email,
      },
    })
  }

  async getVestings(addresses: string[]) {
    return await this.fetchApiResponse<VestingWithLogs[]>(`/vesting`, { method: 'POST', json: { addresses } })
  }

  static parseProposal(proposal: ProposalWithProject): ProposalWithProject {
    return {
      ...proposal,
      start_at: new Date(proposal.start_at),
      finish_at: new Date(proposal.finish_at),
      updated_at: new Date(proposal.updated_at),
      created_at: new Date(proposal.created_at),
    }
  }

  async getProposals(filters: Partial<GetProposalsFilter> = {}) {
    const query = this.toQueryString(filters)

    const proposals = await this.fetch<ApiResponse<ProposalAttributes[]> & { total: number }>(`/proposals${query}`, {
      method: 'GET',
    })

    return {
      ...proposals,
      data: proposals.data.map((proposal) => Governance.parseProposal(proposal)),
    }
  }

  async getProjects(from?: Date, to?: Date) {
    const params = new URLSearchParams()
    if (from) {
      params.append('from', from.toISOString().split('T')[0])
    }
    if (to) {
      params.append('to', to.toISOString().split('T')[0])
    }
    const paramsStr = params.toString()
    const proposals = await this.fetchApiResponse<ProposalProject[]>(`/projects${paramsStr ? `?${paramsStr}` : ''}`)

    return proposals
  }

  async getVpDistribution(address: string, proposalSnapshotId?: string) {
    const snapshotId = proposalSnapshotId ? `/${proposalSnapshotId}` : ''
    const url = `/snapshot/vp-distribution/${address}${snapshotId}`
    return await this.fetchApiResponse<VpDistribution>(url)
  }
}

type ApiResponse<D> = { ok: boolean; data: D }

type NewsletterSubscriptionResult = {
  email: string
  error: boolean
  details: string | null
}

enum VestingStatus {
  Pending = 'Pending',
  InProgress = 'In Progress',
  Finished = 'Finished',
  Paused = 'Paused',
  Revoked = 'Revoked',
}

type VestingLog = {
  topic: string
  timestamp: string
  amount?: number
}

type Vesting = {
  start_at: string
  finish_at: string
  released: number
  releasable: number
  vested: number
  total: number
  address: string
  status: VestingStatus
  token: string
}

type VestingWithLogs = Vesting & { logs: VestingLog[] }

type ProposalListFilter = {
  user: string
  type: ProposalType
  subtype?: string
  status: ProposalStatus
  subscribed: boolean | string
  coauthor: boolean
  search?: string | null
  timeFrame?: string | null
  timeFrameKey?: string | null
  order?: 'ASC' | 'DESC'
  snapshotIds?: string
  linkedProposalId?: string
}

type GetProposalsFilter = ProposalListFilter & {
  limit: number
  offset: number
}

interface ProposalWithProject extends ProposalAttributes {
  project_id?: string | null
  project_status?: string | null
}

type ProposalProject = {
  id: string
  project_id?: string | null
  status: string
  title: string
  user: string
  size: number
  type: ProposalType
  about: string
  created_at: number
  configuration: {
    category: string
    tier: string
  }
  funding?: ProjectFunding
  update_timestamp?: number
}

type ProjectFunding = {
  enacted_at?: string
  one_time_payment?: OneTimePayment
  vesting?: Vesting
}

type OneTimePayment = {
  enacting_tx: string
  token?: string
  tx_amount?: number
}

export type VpDistribution = {
  total: number
  own: number
  wMana: number
  land: number
  estate: number
  mana: number
  names: number
  delegated: number
  l1Wearables: number
  rental: number
}
