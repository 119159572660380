function RevocationsCommittee() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21428"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g mask="url(#mask0_8619_21428)">
        <circle cx="16" cy="16" r="15" fill="#fff" stroke="#D80027" strokeWidth="2"></circle>
        <path stroke="#D80027" strokeWidth="2" d="M4 27L28 27"></path>
        <path stroke="#D80027" strokeWidth="2" d="M-1 23L8 23"></path>
        <path stroke="#D80027" strokeWidth="2" d="M24 23L33 23"></path>
        <path stroke="#D80027" strokeWidth="2" d="M-4 19L6 19"></path>
        <path stroke="#D80027" strokeWidth="2" d="M27 19L37 19"></path>
        <mask
          id="mask1_8619_21428"
          style={{ maskType: 'alpha' }}
          width="24"
          height="24"
          x="4"
          y="4"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M4 4H28V28H4z"></path>
        </mask>
        <g stroke="#D80027" strokeWidth="2" mask="url(#mask1_8619_21428)">
          <circle cx="16" cy="16" r="2"></circle>
          <circle cx="16" cy="16" r="11"></circle>
        </g>
        <circle cx="16" cy="16" r="15" stroke="#D80027" strokeWidth="2"></circle>
        <path stroke="#D80027" strokeWidth="2" d="M8 8l16 16M8 24L24 8"></path>
      </g>
    </svg>
  )
}

export default RevocationsCommittee
