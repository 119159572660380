import Lottie from 'react-lottie-player'

import { Mobile, NotMobile } from 'decentraland-ui/dist/components/Media/Media'

import useTransparency from '../../hooks/useTransparency'
import useVestingContractData from '../../hooks/useVestingContractData'
import grantsProgramImage from '../../images/grants-program.png'

import treasuryStrokeMobile from './lotties/treasury-stroke-mobile.json'
import treasuryStroke from './lotties/treasury-stroke.json'

import ExternalInButton from './ExternalInButton'
import SectionParagraph from './SectionParagraph'
import SectionTitle from './SectionTitle'
import './Treasury.css'

const DAO_VESTING_CONTRACT_ADDRESS = '0x7a3abf8897f31b56f09c6f69d074a393a905c1ac'

const numberFormat = Intl.NumberFormat('en')

export default function Treasury() {
  const transparencyData = useTransparency()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const treasuryAmount = (transparencyData || [])?.balances?.reduce((acc: number, item: any) => {
    return acc + item.amount * item.rate
  }, 0)
  const { vestingData } = useVestingContractData([DAO_VESTING_CONTRACT_ADDRESS])
  const { released = 0, vested = 0, total = 0 } = vestingData?.[0] || {}
  const releasedPercentage = (100 * released) / total
  const vestedPercentage = (100 * vested) / total

  return (
    <div className="Home__Card Treasury">
      <div className="Home__CardContainer">
        <SectionTitle linkText="See Full Report" linkHref="https://decentraland.org/governance/transparency">
          DAO Treasury
        </SectionTitle>
        <SectionParagraph>
          The DAO has been granted a 10-year vesting contract worth <strong>222,000,000 MANA.</strong> We use these
          funds to sponsor the growth of the Decentraland platform through community projects.
        </SectionParagraph>
        <div className="Treasury__VestingContract Treasury__VestingContractTotals">
          <span className="Treasury__VestingContractTitle">Vesting Contract</span>
          <div className="Treasury__VestingContractBar">
            <div className="Treasury__VestingContractVestedBar" style={{ width: `${vestedPercentage}%` }} />
            <div className="Treasury__VestingContractReleasedBar" style={{ width: `${releasedPercentage}%` }} />
          </div>
          <div className="Treasury__VestingContractLegends">
            <div className="Treasury_VestingContractLegend">Released</div>
            <div className="Treasury_VestingContractLegend">
              <div className="Treasury__VestingContractVestedDot" />
              Vested
            </div>
            <div className="Treasury_VestingContractLegend">
              <div className="Treasury__VestingContractTotalDot" />
              Total
            </div>
          </div>
        </div>
        <div className="Treasury__VestingContract">
          <span className="Treasury__VestingContractTitle Treasury__DAOTreasuryTitle">DAO Treasury</span>
          <div className="Treasury__Total">${numberFormat.format(Math.round(treasuryAmount))}</div>
        </div>
        <NotMobile>
          <div className="Treasury__AnimatedStroke">
            <Lottie animationData={treasuryStroke} play />
          </div>
        </NotMobile>
        <Mobile>
          <div className="Treasury__AnimatedStrokeMobile">
            <Lottie animationData={treasuryStrokeMobile} play />
          </div>
        </Mobile>
      </div>
      <div className="Treasury__CardsContainer">
        <div className="Treasury__Card Treasury__GrantsProgram">
          <h3 className="Treasury__SectionTitle">Grants Program</h3>
          <p className="Treasury__SectionParagraph">
            Community members can apply for grants by submitting a proposal to the DAO.
          </p>
          <a
            href="https://docs.decentraland.org/player/general/dao/overview/grants-program/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="Treasury__LearnMoreButton">
              Learn more <ExternalInButton />
            </button>
          </a>
          <img className="Treasury__GrantsProgramImage" src={grantsProgramImage} />
        </div>
        <div className="Treasury__Card Treasury__BiddingAndTendering">
          <h3 className="Treasury__SectionTitle">Bidding & Tendering</h3>
          <div className="Treasury__BiddingAndTenderingItem">
            <div>
              <div className="Treasury__BiddingAndTenderingNumber">1</div>
            </div>
            <p className="Treasury__SectionParagraph">Start by validating your idea with the community.</p>
          </div>
          <div className="Treasury__BiddingAndTenderingItem">
            <div>
              <div className="Treasury__BiddingAndTenderingNumber">2</div>
            </div>
            <p className="Treasury__SectionParagraph">
              Refine your pitch into a tender to receive bids from different teams.
            </p>
          </div>
          <div className="Treasury__BiddingAndTenderingItem">
            <div>
              <div className="Treasury__BiddingAndTenderingNumber">3</div>
            </div>
            <p className="Treasury__SectionParagraph">Teams bid to work on your idea</p>
          </div>
          <div className="Treasury__BiddingAndTenderingItem">
            <div>
              <div className="Treasury__BiddingAndTenderingNumber">4</div>
            </div>
            <p className="Treasury__SectionParagraph">Funding gets assigned to the selected team.</p>
          </div>
          <div className="Treasury__BiddingAndTenderingConnectingLine" />
          <a
            href="https://www.notion.so/dcl-dao/Bidding-Tendering-Flow-308015d8f18147aab717c0bdefed37d3?pvs=4"
            target="_blank"
            rel="noreferrer"
          >
            <button className="Treasury__LearnMoreButton">
              Learn more <ExternalInButton />
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}
