import Lottie from 'react-lottie-player'

import { useMobileMediaQuery } from 'decentraland-ui/dist/components/Media/Media'

import howDoesItWorkMobile1 from './lotties/how-does-it-work-mobile-1.json'
import howDoesItWorkMobile2 from './lotties/how-does-it-work-mobile-2.json'
import howDoesItWorkMobile3 from './lotties/how-does-it-work-mobile-3.json'
import howDoesItWork from './lotties/how-does-it-work.json'

import './HowDoesItWork.css'
import SectionParagraph from './SectionParagraph'
import SectionTitle from './SectionTitle'

const Title = () => {
  return (
    <div className="HowDoesItWork__MiniCardContainer">
      <div className="HowDoesItWork__MiniCard">
        <SectionTitle withMargin={false}>How does it work?</SectionTitle>
      </div>
    </div>
  )
}

const GridFirstTextItem = () => {
  return (
    <div className="HowDoesItWork__GridItem">
      <h4 className="HowDoesItWork__GridItemTitle">1. Discuss and publish a proposal</h4>
      <SectionParagraph>
        Exchange ideas with the community and write a proposal that contributes to the ecosystem.
      </SectionParagraph>
    </div>
  )
}

const GridSecondTextItem = () => {
  return (
    <div className="HowDoesItWork__GridItem">
      <h4 className="HowDoesItWork__GridItemTitle">2. Receive votes and questions</h4>
      <SectionParagraph>
        People will vote and comment. Follow the progress and answer any questions that might arise.
      </SectionParagraph>
    </div>
  )
}

const GridThirdTextItem = () => {
  return (
    <div className="HowDoesItWork__GridItem">
      <h4 className="HowDoesItWork__GridItemTitle">3. Proposal enacted</h4>
      <SectionParagraph>
        If the voting meets a threshold, the proposal is approved and enacted!{' '}
        <a
          href="https://decentraland.org/governance/proposals?status=active"
          className="HowDoesItWork__Link"
          target="_blank"
          rel="noreferrer"
        >
          Explore active proposals
        </a>
        .
      </SectionParagraph>
    </div>
  )
}

export default function HowDoesItWork() {
  const isMobile = useMobileMediaQuery()

  return (
    <div className="HowDoesItWork">
      {isMobile && (
        <div>
          <Title />
          <div>
            <div className="HowDoesItWork__MobileGrid">
              <GridFirstTextItem />
              <div className="HowDoesItWork__GridItem HowDoesItWork__GridItem--animated">
                <Lottie animationData={howDoesItWorkMobile1} play />
              </div>
              <GridSecondTextItem />
              <div className="HowDoesItWork__GridItem HowDoesItWork__GridItem--animated">
                <Lottie animationData={howDoesItWorkMobile2} play />
              </div>
              <GridThirdTextItem />
              <div className="HowDoesItWork__GridItem HowDoesItWork__GridItem--animated">
                <Lottie animationData={howDoesItWorkMobile3} play />
              </div>
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <>
          <div className="HowDoesItWork__AnimatedGrid">
            <Lottie animationData={howDoesItWork} play />
          </div>
          <div className="HowDoesItWork__DesktopContainer">
            <Title />
            <div>
              <div className="HowDoesItWork__Grid">
                <GridFirstTextItem />
                <div className="HowDoesItWork__GridItem HowDoesItWork__GridItem--transparent" />
                <GridSecondTextItem />
                <div className="HowDoesItWork__GridItem HowDoesItWork__GridItem--transparent" />
                <GridThirdTextItem />
                <div className="HowDoesItWork__GridItem HowDoesItWork__GridItem--transparent" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
