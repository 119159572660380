function StrategicUnitSquad() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21594"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g stroke="#736E7D" strokeWidth="2" mask="url(#mask0_8619_21594)">
        <circle cx="16" cy="16" r="15"></circle>
        <circle cx="16" cy="16" r="5"></circle>
        <circle cx="16" cy="16" r="15"></circle>
        <path fill="#fff" d="M31.586 37H.414L16 21.414 31.586 37z"></path>
        <path d="M16 20L16 38"></path>
        <path fill="#fff" d="M.414-5h31.172L16 10.586.414-5z"></path>
        <path d="M16 12L16 -6"></path>
        <path fill="#fff" d="M-6 31.586V.414L9.586 16-6 31.586z"></path>
        <path d="M11 16L-7 16"></path>
        <path fill="#fff" d="M38 .414v31.172L22.414 16 38 .414z"></path>
        <path d="M21 16L39 16"></path>
        <circle cx="16" cy="16" r="15"></circle>
      </g>
      <circle cx="16" cy="16" r="1" stroke="#736E7D" strokeWidth="2"></circle>
    </svg>
  )
}

export default StrategicUnitSquad
