function DAOCommittee() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21634"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g mask="url(#mask0_8619_21634)">
        <circle cx="16" cy="16" r="15" fill="#fff" stroke="#FF7439" strokeWidth="2"></circle>
        <path stroke="#FF7439" strokeWidth="2" d="M4 27L28 27"></path>
        <path stroke="#FF7439" strokeWidth="2" d="M-1 23L33 23"></path>
        <path stroke="#FF7439" strokeWidth="2" d="M-1 19L33 19"></path>
        <mask
          id="mask1_8619_21634"
          style={{ maskType: 'alpha' }}
          width="26"
          height="15"
          x="3"
          y="3"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M3 3H29V18H3z"></path>
        </mask>
        <g mask="url(#mask1_8619_21634)">
          <circle cx="16" cy="16" r="11" stroke="#FF7439" strokeWidth="2"></circle>
        </g>
        <circle cx="16" cy="16" r="15" stroke="#FF7439" strokeWidth="2"></circle>
        <path fill="#fff" stroke="#FF7439" strokeWidth="2" d="M25.586 23H6.414L16 13.414 25.586 23z"></path>
        <path stroke="#FF7439" strokeWidth="2" d="M16 14L16 24"></path>
        <circle cx="16" cy="9" r="1" fill="#FF7439"></circle>
      </g>
    </svg>
  )
}

export default DAOCommittee
