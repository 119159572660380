import classNames from 'classnames'

import './SectionParagraph.css'

interface Props {
  className?: string
  children: React.ReactNode
}

export default function SectionParagraph({ className, children }: Props) {
  return <p className={classNames('SectionParagraph', className)}>{children}</p>
}
