function WearableCurationCommittee() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21345"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g mask="url(#mask0_8619_21345)">
        <circle cx="16" cy="16" r="15" fill="#fff" stroke="#FF5AFE" strokeWidth="2"></circle>
        <path stroke="#FF5AFE" strokeWidth="2" d="M4 27L28 27"></path>
        <path stroke="#FF5AFE" strokeWidth="2" d="M-1 23L33 23"></path>
        <path stroke="#FF5AFE" strokeWidth="2" d="M-1 19L33 19"></path>
        <circle cx="7" cy="2" r="6" stroke="#FF5AFE" strokeWidth="2"></circle>
        <path fill="#fff" stroke="#FF5AFE" strokeWidth="2" d="M14.586 19H-.586L7 11.414 14.586 19z"></path>
        <path stroke="#FF5AFE" strokeWidth="2" d="M7 12L7 18"></path>
        <path fill="#fff" stroke="#FF5AFE" strokeWidth="2" d="M31.586 23H8.414L20 11.414 31.586 23z"></path>
        <path stroke="#FF5AFE" strokeWidth="2" d="M20 12L20 22"></path>
        <circle cx="20" cy="6" r="2" fill="#FF5AFE"></circle>
        <circle cx="16" cy="16" r="15" stroke="#FF5AFE" strokeWidth="2"></circle>
      </g>
    </svg>
  )
}

export default WearableCurationCommittee
