/* eslint-disable @typescript-eslint/no-explicit-any */
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
const METHODS_WITH_BODY: HttpMethod[] = ['POST', 'PUT', 'PATCH']

export type ApiOptions = {
  method: HttpMethod
  json?: any
  headers?: Record<string, string>
}

export default abstract class API {
  private readonly baseUrl: string
  private defaultHeaders: Record<string, string> = {}

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  protected async fetch<T>(endpoint: string, options: ApiOptions = { method: 'GET' }): Promise<T> {
    const { method, json, headers = {} } = options
    const jsonHeaders = this.getJsonHeaders(method, json)

    const finalHeaders = {
      ...this.defaultHeaders,
      ...headers,
      ...jsonHeaders,
    }

    const response = await fetch(this.url(endpoint), {
      method,
      headers: finalHeaders,
      body: json ? JSON.stringify(json) : undefined,
    })

    await this.checkForErrors(response)

    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.includes('application/json')) {
      return response.json()
    } else {
      throw new Error(`Expected JSON response but received non-JSON content: ${await response.text()}`)
    }
  }

  private async checkForErrors(response: Response) {
    if (!response.ok) {
      let errorBody = await response.text()
      const contentType = response.headers.get('Content-Type')
      if (contentType && contentType.includes('application/json')) {
        try {
          const errorJson = await response.json()
          errorBody = JSON.stringify(errorJson)
        } catch (e) {
          // If JSON parsing fails, fallback to using the text response (errorBody already set)
        }
      }
      throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`)
    }
  }

  private getJsonHeaders(method: HttpMethod, json?: any) {
    const hasBody = METHODS_WITH_BODY.includes(method) && json !== undefined
    const jsonHeaders: Record<string, string> = {}
    if (hasBody) {
      jsonHeaders['Content-Type'] = 'application/json'
    }
    return jsonHeaders
  }

  private url(endpoint: string) {
    return `${this.baseUrl}${endpoint}`
  }

  protected setDefaultHeader(key: string, value: string): void {
    this.defaultHeaders[key] = value
  }

  protected toQueryString(params: Record<string, unknown>): string {
    const searchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => searchParams.append(key, String(item)))
      } else {
        searchParams.append(key, String(value))
      }
    })
    return searchParams.toString() ? `?${searchParams.toString()}` : ''
  }
}
