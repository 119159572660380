import './GrantsNavigation.css'
import Link from '../Typography/Link'

const tabsContents = [
  ['/grants', 'Decentraland Grants'],
  ['/grants/recipients', 'Grant Recipients'],
  ['/grants/resources', 'Resources'],
]

interface Props {
  activeTab: string
}

export default function GrantsNavigation({ activeTab }: Props) {
  return (
    <div className="navTabs">
      <div className="barContainer">
        <div className="tabsContainer">
          {tabsContents.map((tab) => {
            const isActive = activeTab === tab[0]
            return (
              <Link key={tab[0]} href={`${tab[0]}`}>
                <div className={`navTab ${isActive ? 'navTab--active' : ''}`}>
                  {`${tab[1]}`}
                  {isActive ? <div className="active-bar" /> : null}
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}
