import { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import { motion, useAnimation } from 'framer-motion'

import { CircledChevron } from '../Icon'

import './VoteVP.css'
import VoteVPCarouselItem from './VoteVPCarouselItem'

const ITEM_WIDTH = 161
const CENTER_INDEX = 7
const GAP = 20

export default function VoteVPCarousel({ avatars }: { avatars: string[] }) {
  const [width, setWidth] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const carouselRef = useRef<HTMLDivElement>(null)
  const controls = useAnimation()

  useEffect(() => {
    if (width === 0 && carouselRef.current) {
      setWidth(carouselRef.current.offsetWidth)
    }
  }, [width])

  useEffect(() => {
    if (width !== 0) {
      setSelectedIndex(CENTER_INDEX)
    }
  }, [width])

  const handleItemClick = (index: number) => {
    if (carouselRef.current) {
      const centerX = width / 2
      const itemCenterX = index * (ITEM_WIDTH + GAP) + ITEM_WIDTH / 2
      const newX = Math.round(centerX - itemCenterX) - index

      controls.start({
        x: newX,
        transition: { type: 'spring', stiffness: 300, damping: 30 },
      })

      setSelectedIndex(index)
    }
  }

  const handleChevronClick = (direction: 'left' | 'right') => {
    const newIndex = (selectedIndex ?? CENTER_INDEX) + (direction === 'left' ? -1 : 1)

    if (newIndex < 0 || newIndex >= avatars.length) {
      return
    }

    handleItemClick(newIndex)
  }

  return (
    <div className="VoteVPCarousel">
      <CircledChevron
        className={classNames('VoteVPCarousel__LeftChevron', selectedIndex === 0 && 'VoteVPCarousel__Chevron--hidden')}
        onClick={() => handleChevronClick('left')}
      />
      <motion.div ref={carouselRef} className="VoteVpCarousel__Container" animate={controls}>
        {avatars.map((address, index) => (
          <VoteVPCarouselItem
            key={address}
            address={address}
            visible={selectedIndex === index || selectedIndex === index - 1 || selectedIndex === index + 1}
            onClick={() => handleItemClick(index)}
            isSelected={selectedIndex === index}
          />
        ))}
      </motion.div>
      <CircledChevron
        className={classNames(
          'VoteVPCarousel__RightChevron',
          selectedIndex === avatars.length - 1 && 'VoteVPCarousel__Chevron--hidden'
        )}
        onClick={() => handleChevronClick('right')}
      />
    </div>
  )
}
