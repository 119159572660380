import React from 'react'

import './Pill.css'

export enum PillColor {
  Green = 'green',
  Blue = 'blue',
  Red = 'red',
  Gray = 'gray',
  Purple = 'purple',
  Fuchsia = 'fuchsia',
  Orange = 'orange',
  Yellow = 'yellow',
  Aqua = 'aqua',
}

export type Props = {
  children: React.ReactText
  color?: string
  icon?: React.ReactNode
}

export default React.memo(function Pill({ children, color = 'green', icon }: Props) {
  return (
    <div className={`Pill Pill--${color}`}>
      {icon}
      <span>{children}</span>
    </div>
  )
})
