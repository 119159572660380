function FacilitationSquad() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21553"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g stroke="#9200D7" strokeWidth="2" mask="url(#mask0_8619_21553)">
        <circle cx="16" cy="16" r="15"></circle>
        <path d="M4 27L28 27"></path>
        <path d="M-1 23L33 23"></path>
        <path d="M-1 19L33 19"></path>
        <circle cx="16" cy="16" r="5"></circle>
        <circle cx="16" cy="16" r="15"></circle>
        <path fill="#fff" d="M31.586 33H.414L16 17.414 31.586 33z"></path>
        <path fill="#fff" d="M25.586 33H6.414L16 23.414 25.586 33z"></path>
        <path d="M16 17L16 33"></path>
        <circle cx="16" cy="16" r="15"></circle>
      </g>
      <circle cx="16" cy="6" r="1" stroke="#9200D7" strokeWidth="2"></circle>
    </svg>
  )
}

export default FacilitationSquad
