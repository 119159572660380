function BugIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <path
        stroke="#FF2D55"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 12.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM2.5 8h-2M.5 11.5a3.46 3.46 0 002.63-1.2M3.13 5.7A3.46 3.46 0 00.5 4.5M11.5 8h2M13.5 11.5a3.46 3.46 0 01-2.63-1.2M10.87 5.7a3.46 3.46 0 012.63-1.2M8.24 3.67A2.5 2.5 0 009.5 1.5M4.5 1.5a2.5 2.5 0 001.26 2.17M2.61 7h8.78"
      ></path>
    </svg>
  )
}

export default BugIcon
