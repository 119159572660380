function GovernanceSquad() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <mask
        id="mask0_8619_21468"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="16" cy="16" r="16" fill="#3C24B3"></circle>
      </mask>
      <g mask="url(#mask0_8619_21468)">
        <circle cx="16" cy="16" r="15" stroke="#FFB03F" strokeWidth="2"></circle>
        <path stroke="#FFB03F" strokeWidth="2" d="M-1 23L33 23"></path>
        <mask
          id="mask1_8619_21468"
          style={{ maskType: 'alpha' }}
          width="24"
          height="19"
          x="4"
          y="5"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#D9D9D9" d="M4 5H28V24H4z"></path>
        </mask>
        <g mask="url(#mask1_8619_21468)">
          <circle cx="16" cy="16" r="8" stroke="#FFB03F" strokeWidth="2"></circle>
        </g>
        <circle cx="16" cy="15" r="1" stroke="#FFB03F" strokeWidth="2"></circle>
        <circle cx="16" cy="16" r="15" stroke="#FFB03F" strokeWidth="2"></circle>
        <path fill="#fff" stroke="#FFB03F" strokeWidth="2" d="M31.586 24H16.414L24 16.414 31.586 24z"></path>
        <path stroke="#FFB03F" strokeWidth="2" d="M24 16L24 25"></path>
        <path fill="#fff" stroke="#FFB03F" strokeWidth="2" d="M24.586 31H-8.586L8 14.414 24.586 31z"></path>
        <path stroke="#FFB03F" strokeWidth="2" d="M8 15L8 32"></path>
        <circle cx="16" cy="16" r="15" stroke="#FFB03F" strokeWidth="2"></circle>
      </g>
    </svg>
  )
}

export default GovernanceSquad
