import classNames from 'classnames'

import CategoryPill from './CategoryPill'
import Heading from './Heading'
import './ProposalPreviewCard.css'
import ProposalPreviewCardSection from './ProposalPreviewCardSection'
import StatusPill from './StatusPill'
import Username from './Username'
import { ProposalAttributes } from './types'

interface Props {
  proposal: ProposalAttributes
}

export default function ProposalPreviewCard({ proposal }: Props) {
  const { title, user, type, status } = proposal

  return (
    <a
      className={classNames('ProposalPreviewCard', `ProposalPreviewCard--category`)}
      href={`https://decentraland.org/governance/proposal?id=${proposal.id}`}
      target="_blank"
      rel="noreferrer"
    >
      <ProposalPreviewCardSection>
        <Username className="ProposalPreviewCard__Avatar" address={user} variant="avatar" size="md" />
        <div className="ProposalPreviewCard__TextContainer">
          <Heading as="h3" size="xs" weight="semi-bold" className="ProposalPreviewCard__Title">
            {title}
          </Heading>
          <span className="ProposalPreviewCard__Details">
            <CategoryPill className="ProposalPreviewCard__Pill" proposalType={type} size="sm" />
            <StatusPill status={status} size="sm" />
            <span className="ProposalPreviewCard__DetailsItem ProposalPreviewCard__UsernameContainer">
              By
              <Username className="ProposalPreviewCard__Username" address={user} variant="address" />
            </span>
          </span>
        </div>
      </ProposalPreviewCardSection>
    </a>
  )
}
