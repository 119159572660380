function DiscordIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <path stroke="#FF2D55" d="M4.612 7a.5.5 0 100-1 .5.5 0 000 1zM9.112 7a.5.5 0 100-1 .5.5 0 000 1z"></path>
      <path
        stroke="#FF2D55"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.858 9.864c0-2.401.858-5.574 1.715-6.861 0 0 .858-.429 4.289-.429 3.43 0 4.288.43 4.288.43.858 1.286 1.716 4.459 1.716 6.86-.286.43-1.287 1.373-3.002 1.716l-1.51-1.886a6.586 6.586 0 01-2.985 0L3.86 11.58c-1.715-.343-2.716-1.287-3.002-1.716z"
      ></path>
      <path
        stroke="#FF2D55"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.86 9.007c.261.261.81.523 1.509.687a6.586 6.586 0 002.986 0c.699-.164 1.247-.426 1.509-.687"
      ></path>
    </svg>
  )
}

export default DiscordIcon
