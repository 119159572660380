import { useQuery } from '@tanstack/react-query'
import { Loader } from 'decentraland-ui/dist/components/Loader/Loader'

import { Governance } from '../../clients/Governance'
import GrantsFooter from '../../components/GrantsFooter/GrantsFooter'
import Head from '../../components/Head'
import Username from '../../components/Home/ProposalPreviewCard/Username'
import { ProposalType } from '../../components/Home/ProposalPreviewCard/types'
import { External, IconA, IconCU, IconD, IconIWC, IconP, IconS, IconSMC } from '../../components/Icon'
import GrantsNavigation from '../../components/Navigation/GrantsNavigation'
import Pill from '../../components/Pill/Pill'
import header_recipients from '../../images/grants/header_recipients.webp'

import './grants.css'
import initBlog from './initBlog.json'
import initProjects from './initProjects.json'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBlogImageURL = (id: string, blogList: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return blogList.includes.Asset.find((asset: any) => asset.sys.id === id).fields.file.url.slice(23)
}

const getCategoryElements = (name: string) => {
  if (name === 'Accelerator') return { icon: <IconA />, color: 'green' }
  if (name === 'Core Unit') return { icon: <IconCU />, color: 'blue' }
  if (name === 'Documentation') return { icon: <IconD />, color: 'purple' }
  if (name === 'In-World Content') return { icon: <IconIWC />, color: 'red' }
  if (name === 'Platform') return { icon: <IconP />, color: 'fuchsia' }
  if (name === 'Social Media Content') return { icon: <IconSMC />, color: 'yellow' }
  if (name === 'Sponsorship') return { icon: <IconS />, color: 'orange' }
  return {}
}

const restrictedCategories = ['Community', 'Content Creator', 'Gaming', 'Platform Contributor']

type BlogList = {
  items: {
    fields: {
      id: string
      title: string
      publishedDate: string
      image: {
        sys: {
          id: string
        }
      }
    }
  }[]
}

export default function GrantsRecipientsPage() {
  const { data: projects, isLoading: isLoadingProjects } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      try {
        const response = await Governance.get().getProjects()

        return response
          .sort((a, b) => (b.update_timestamp || 0) - (a.update_timestamp || 0))
          .filter(
            ({ status, type, configuration }) =>
              status === 'in_progress' &&
              type === ProposalType.Grant &&
              !restrictedCategories.includes(configuration.category)
          )
      } catch {
        return initProjects
      }
    },
  })

  const { data: blogList } = useQuery({
    queryKey: ['blogList'],
    initialData: initBlog,
    queryFn: async () => {
      const response = await (
        await fetch(
          'https://cdn.contentful.com/spaces/ea2ybdmmn1kv/environments/master/entries?content_type=blog_post&fields.category.sys.id=6cUDvIsbvmtRbs1Q6Kv9zW&order=-fields.publishedDate&limit=6&skip=0&access_token=W64m2bOlvJkjFM_T-kW7q9EBcVjw1drdGX6a03bS5Hw'
        )
      ).json()

      return response as BlogList
    },
  })

  return (
    <>
      <Head
        title="Decentraland Grants Resources"
        description="Every quarter, the Decentraland DAO budget is renewed, and we encourage you to apply in any of the 7 categories available"
      />

      <GrantsNavigation activeTab="/grants/recipients" />

      <div className="grantsHeader">
        <div className="grantsHeader__container">
          <div className="grantsHeader__leftPanel">
            <div className="grantsHeader__title">Grant Recipients</div>
            <div className="grantsHeader__text">Explore the latest projects and read about the grant recipients.</div>
          </div>
          <img className="grantsHeader__logo" src={header_recipients} alt="Grants resources" />
        </div>
      </div>

      <div className="resources__Container">
        <div className="resourcesSection__title">Active projects</div>
        {isLoadingProjects && (
          <div className="projects__loading">
            <Loader active />
          </div>
        )}
        {!isLoadingProjects && projects && (
          <div className="projects__panel">
            <div className="projects__column">
              {projects?.slice(0, 6).map(({ title, size, configuration, id, user }) => {
                const pillData = getCategoryElements(configuration.category)

                return (
                  <a
                    key={id}
                    className="project__container"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://decentraland.org/governance/proposal/?id=${id}`}
                  >
                    <div>
                      <div className="project__metadata">
                        <Pill color={pillData.color}>{configuration.category}</Pill> ${size}
                      </div>
                      <div className="project__title">{title}</div>
                    </div>
                    <Username variant="avatar" size="lg" address={user} />
                  </a>
                )
              })}
            </div>
          </div>
        )}
        <div className="grantsCtaContainer">
          <a
            className="cta grantsHero__container__cta"
            target="_blank"
            rel="noreferrer"
            href="https://decentraland.org/governance/projects/"
          >
            EXPLORE ALL PROJECTS
            <External white />
          </a>
        </div>

        <div className="resourcesSection__title">Highlighted Projects</div>
        <div className="blog-post__wrapper">
          {blogList.items.map((item) => {
            return (
              <div key={item.fields.id} className="post-card__card">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://decentraland.org/blog/community-projects/${item.fields.id}`}
                  className="dg Link Link--pointer"
                >
                  <div
                    className="post-card__image"
                    style={{
                      backgroundImage: `url('https://cms-images.decentraland.org/${getBlogImageURL(
                        item.fields.image.sys.id,
                        blogList
                      )}')`,
                    }}
                  />
                </a>
                <div className="post-card__info">
                  <span className="post-card__date">{new Date(item.fields.publishedDate).toDateString()}</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://decentraland.org/blog/community-projects/${item.fields.id}`}
                  >
                    <h2>{item.fields.title}</h2>
                  </a>
                </div>
              </div>
            )
          })}
        </div>

        <div className="grantsCtaContainer">
          <a
            className="cta grantsHero__container__cta"
            target="_blank"
            rel="noreferrer"
            href="https://decentraland.org/blog/community-highlights"
          >
            READ MORE
            <External white />
          </a>
        </div>
      </div>
      <GrantsFooter />
    </>
  )
}
