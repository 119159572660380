function ForumIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g stroke="#FF2D55" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_8873_90)">
        <path d="M9.25 5a4.25 4.25 0 013.54 6.6l.71 1.9-2.39-.43A4.25 4.25 0 119.25 5z"></path>
        <path d="M9.86 2.51A5.24 5.24 0 00.5 5.75a5.2 5.2 0 00.88 2.91L.5 11l2.12-.38"></path>
      </g>
      <defs>
        <clipPath id="clip0_8873_90">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default ForumIcon
