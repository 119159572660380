import classNames from 'classnames'

import { External } from '../Icon'

import './SectionTitle.css'

interface Props {
  children: React.ReactNode
  linkText?: string
  linkHref?: string
  withMargin?: boolean
}

export default function SectionTitle({ children, linkText, linkHref, withMargin = true }: Props) {
  return (
    <div className={classNames('SectionTitle', withMargin && 'SectionTitle--withMargin')}>
      <h3 className="SectionTitle__Title">{children}</h3>
      {!!linkText && !!linkHref && (
        <span>
          <a className="SectionTitle__Link" href={linkHref} target="_blank" rel="noreferrer">
            {linkText}
          </a>
          <External />
        </span>
      )}
    </div>
  )
}
