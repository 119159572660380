import { useMobileMediaQuery } from 'decentraland-ui/dist/components/Media/Media'

import SectionParagraph from './SectionParagraph'
import SectionTitle from './SectionTitle'
import './VoteVP.css'
import VoteVPAvatarSelector from './VoteVPAvatarSelector'
import VoteVPCarousel from './VoteVPCarousel'

const AVATARS = [
  '0x0636211443e91468ee3657e1d7faede7059c4843',
  '0x75e1d32289679dfcb2f01fbc0e043b3d7f9cd443',
  '0x521b0fef9cdcf250abaf8e7bc798cbe13fa98692',
  '0x6caa802c6895ecd31601518c7f6b9ef6f9349238',
  '0xd6eff8f07caf3443a1178407d3de4129149d6ef6',
  '0xd5e9ef1cedad0d135d543d286a2c190b16cbb89e',
  '0x3a49309413793b32f6a308769220147fedbffa5f',
  '0xfcacace2713d41c02666e2643e5583793196c935',
  '0x69d494eade06850b5074b502faa3666ec19f0787',
  '0xea09431b6ddc2a52a997d690573934a72c8eb95b',
  '0xb0145ae156d201d6e371d07265fe3c045071c967',
  '0xac097c7e31a2add6244a0f36dba5776d04f5a3fc',
  '0x52f8d5518a05366989b3baad9c8e1129ca317fc7',
  '0x13543d97bba82c4265534fbe3e4fef176ca09470',
  '0xed0e0cb94f60f72ec94bef848f5df4cbd365af1d',
]

export default function VoteVP() {
  const isMobile = useMobileMediaQuery()

  return (
    <div className="VoteVp">
      <div className="Home__Card">
        <div className="Home__CardContainer">
          <SectionTitle>Vote and make your voice heard</SectionTitle>
          <SectionParagraph>
            DAO users vote with their voting power (VP). VP is calculated from the total balance of MANA, LAND or NAME
            associated with the user wallet. In short, the more MANA you have, the greater your voting power and impact
            on the community.
          </SectionParagraph>
        </div>
      </div>
      {!isMobile && <VoteVPCarousel avatars={AVATARS} />}
      {isMobile && <VoteVPAvatarSelector avatars={AVATARS} />}
    </div>
  )
}
