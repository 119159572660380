import { useQuery } from '@tanstack/react-query'

export default function useTransparency() {
  const { data } = useQuery({
    queryKey: ['transparency'],
    queryFn: async () => {
      const response = await (await fetch('https://data.decentraland.vote/api.json')).json()

      return response
    },
  })

  return data
}
