import { useCallback, useEffect, useMemo, useState } from 'react'

import { PreviewCamera, PreviewEmote } from '@dcl/schemas'
import classNames from 'classnames'
import { WearablePreview } from 'decentraland-ui/dist/components/WearablePreview/WearablePreview'
import { motion } from 'framer-motion'

import useDclProfile from '../../hooks/useDclProfile'
import defaultAvatar from '../../images/default-avatar.png'
import { External } from '../Icon'

import VPDistributionChart from './VPDistributionChart'
import './VoteVPCarouselItem.css'

interface Props {
  address: string
  isSelected: boolean
  visible: boolean
  onClick?: () => void
}

function shortenAddress(address: string | null | undefined) {
  if (!address) {
    return ''
  }

  return `${address.substring(0, 6)}...${address.substring(38, 42)}`
}

export default function VoteVPCarouselItem({ address, isSelected = false, onClick, visible }: Props) {
  const { profile } = useDclProfile(address)

  const [wearablePreviewController, setWearablePreviewController] = useState<any>()

  const handleLoad = useCallback(() => {
    setWearablePreviewController(WearablePreview.createController('wearable-preview'))
  }, [])

  const previewEmote = useMemo(() => {
    const poses = [PreviewEmote.DAB, PreviewEmote.FIST_PUMP, PreviewEmote.DANCE, PreviewEmote.HEAD_EXPLODE]
    return poses[(Math.random() * poses.length) | 0]
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (wearablePreviewController) {
        wearablePreviewController.emote.play()
      }
    }, 20000)

    return () => clearInterval(interval)
  }, [wearablePreviewController])

  const hasAvatar = !!profile?.avatar?.snapshots?.body

  return (
    <motion.div
      className={classNames(
        'VoteVPCarouselItem',
        isSelected && 'VoteVPCarouselItem--selected',
        !visible && 'VoteVPCarouselItem--hidden'
      )}
      onClick={onClick}
    >
      <div className="VoteVPCarouselItem__VisibleSide">
        <div className="VoteVPCarouselItem__Avatar">
          {isSelected && (
            <WearablePreview
              id="wearable-preview"
              profile={address}
              onLoad={handleLoad}
              background={'FFFFFF'}
              emote={previewEmote}
              disableAutoRotate={true}
              camera={PreviewCamera.STATIC}
            />
          )}
          {!isSelected && (
            <img
              className={classNames(
                'VoteVPCarouselItem__AvatarImage',
                !hasAvatar && 'VoteVPCarouselItem__AvatarImage--default'
              )}
              src={profile?.avatar?.snapshots?.body || defaultAvatar}
            />
          )}
        </div>
      </div>
      <div className="VoteVPCarouselItem__HiddenSide">
        <div className="VoteVPCarouselItem__Username">
          <a
            href={`https://decentraland.org/governance/profile?address=${profile.address}`}
            target="_blank"
            rel="noreferrer"
          >
            {profile.username || shortenAddress(address)}
          </a>
          <External black />
        </div>
        <VPDistributionChart address={address} />
      </div>
      <div
        className={classNames('VoteVPCarouselItem__Gradient', isSelected && 'VoteVPCarouselItem__Gradient--selected')}
      />
    </motion.div>
  )
}
