import React from 'react'

import { useQuery } from '@tanstack/react-query'

import { Governance } from '../../clients/Governance'
import GrantsFooter from '../../components/GrantsFooter/GrantsFooter'
import Head from '../../components/Head'
import { ProposalStatus, ProposalType } from '../../components/Home/ProposalPreviewCard/types'
import { Chevron, IconA, IconCU, IconD, IconIWC, IconP, IconS, IconSMC } from '../../components/Icon'
import GrantsNavigation from '../../components/Navigation/GrantsNavigation'
import useTransparency from '../../hooks/useTransparency'
import dax from '../../images/grants/dax.webp'
import gc from '../../images/grants/gc.webp'
import nf from '../../images/grants/nf.webp'
import services from '../../images/grants/services.webp'

import './grants.css'

const numberFormat = Intl.NumberFormat('en')

const ctaToggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  event.currentTarget.classList.toggle('faq--collapsed')
}

const onFaqLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  event.stopPropagation()
}

export default function GrantsPage() {
  const { data: applicationsCount } = useQuery({
    queryKey: ['applicationsCount'],
    queryFn: async () => (await Governance.get().getProposals({ type: ProposalType.Grant })).total || 0,
  })

  const { data: projectsCount } = useQuery({
    queryKey: ['projectsCount'],
    queryFn: async () =>
      (await Governance.get().getProposals({ type: ProposalType.Grant, status: ProposalStatus.Enacted })).total || 0,
  })

  const transparencyData = useTransparency()

  return (
    <>
      <Head
        title="Decentraland Grants"
        description="Every quarter, the Decentraland DAO budget is renewed, and we encourage you to apply in any of the 7 categories available"
      />

      <GrantsNavigation activeTab="/grants" />

      <div className="bg-elipse-blue" />
      <div className="bg-elipse-red" />
      <div className="grantsHero">
        <div className="grantsHero__container">
          <div className="grantsHero__container__title">
            {`Let's build a decentralized metaverse `}
            <span>together.</span>
          </div>
          <div className="grantsHero__container__description">
            Every quarter, the grants program budget is renewed.
            <br />
            Request a grant and bring your project to Decentraland!
          </div>
          <a
            className="cta grantsHero__container__cta"
            target="_blank"
            rel="noreferrer"
            href="https://decentraland.org/governance/submit/grant/"
          >
            REQUEST A GRANT
          </a>
        </div>
        <img alt="grantsHero images" className="grantsHero__images" />
      </div>
      <div className="kpis">
        <div className="kpis__description">About our grants program</div>
        <div className="kpis__cards">
          <div className="kpis__card">
            <p className="kpis__card--title">{projectsCount || 0}</p>
            <p className="kpis__card--text">projects funded</p>
          </div>
          <div className="kpis__card">
            <p className="kpis__card--title">${numberFormat.format(transparencyData?.funding.total || 0)}</p>
            <p className="kpis__card--text">allocated in funding</p>
          </div>
          <div className="kpis__card">
            <p className="kpis__card--title">{applicationsCount || 0}</p>
            <p className="kpis__card--text">processed applications</p>
          </div>
        </div>
      </div>
      <div className="categories">
        <div className="categories__titleContainer">
          <div className="categories__title">What projects does the program fund?</div>
          <div className="categories__subtitle">Currently, DAO grants will fund projects within seven categories</div>
          <div className="categories__notice">
            Note: According to a Governance Proposal that passed on February 28, 2024, only Platform (50% of grants
            program budget) and Core Unit (10% of grants program budget) grant proposals can be submitted and funded
            throughout 2024.
          </div>
        </div>
        <div className="categories__cards">
          <div className="categories__card">
            <IconIWC />
            <div className="categories__card--title">In world content</div>
            <div className="categories__card--subtitle">
              Development of experiences aiming to improve user retention
            </div>
          </div>
          <div className="categories__card">
            <IconP />
            <div className="categories__card--title">Platform</div>
            <div className="categories__card--subtitle">
              Creation of tools and applications extending our platform and ecosystem
            </div>
          </div>
          <div className="categories__card">
            <IconCU />
            <div className="categories__card--title">Core Unit</div>
            <div className="categories__card--subtitle">Provide core infrastructure and operations for the DAO</div>
          </div>
          <div className="categories__card">
            <IconD />
            <div className="categories__card--title">Documentation</div>
            <div className="categories__card--subtitle">
              Production of free educational content related to Decentraland’s dynamics
            </div>
          </div>
          <div className="categories__card">
            <IconSMC />
            <div className="categories__card--title">Social Media Content</div>
            <div className="categories__card--subtitle">
              Marketing efforts aiming to reach new users and keeping them engaged
            </div>
          </div>
          <div className="categories__card">
            <IconS />
            <div className="categories__card--title">Sponsorship</div>
            <div className="categories__card--subtitle">
              Supporting In-Real-Life initiatives targeting audiences relevant to Decentraland
            </div>
          </div>
          <div className="categories__card">
            <IconA />
            <div className="categories__card--title">Accelerator</div>
            <div className="categories__card--subtitle">
              A private company of for-profit initiative in need of funding
            </div>
          </div>
        </div>
        <div className="categories__ctaContainer">
          <a className="cta" target="_blank" rel="noreferrer" href="https://decentraland.org/governance/submit/grant/">
            REQUEST A GRANT
          </a>
        </div>
      </div>
      <div className="timeline__container">
        <img alt="grant process timeline" className="timeline" />
      </div>

      <div className="faq__container">
        <div className="faq__header">
          <div className="faq__header--title">Frequently asked questions</div>
          <p className="faq__header--subText">If you have more questions, take a look at our resources for grantees.</p>
        </div>
        <div className="faq__list">
          <div className="faq__item faq--collapsed" onClick={ctaToggle}>
            <div className="faq__item--head">
              <div className="faq__item--number">01</div>
              <div className="faq__item--question">What is the Grants Program?</div>
              <Chevron className="faq__item--chevron" />
            </div>
            <p className="faq__item--answer">
              The Metaverse is a virtual world that is built on top of the internet. It is a place where users can
              interact with each other and with virtual environments in a more immersive and interactive way.
            </p>
          </div>
          <div className="faq__item faq--collapsed" onClick={ctaToggle}>
            <div className="faq__item--head">
              <div className="faq__item--number">02</div>
              <div className="faq__item--question">Who can apply?</div>
              <Chevron className="faq__item--chevron" />
            </div>
            <p className="faq__item--answer">
              Anyone can apply! To request a grant from the Decentraland DAO, begin by navigating to{' '}
              <a target="_blank" rel="noreferrer" onClick={onFaqLinkClick} href="https://decentraland.org/governance/">
                decentraland.org/governance
              </a>{' '}
              click on the button “start voting” and sign in with your wallet.
              <br />
              <br />
              Head to the Grants Projects tab and on the lower left side of the platform you’ll see a REQUEST A GRANT
              button. You have a shortcut{' '}
              <a
                target="_blank"
                rel="noreferrer"
                onClick={onFaqLinkClick}
                href="https://decentraland.org/governance/submit/grant/"
              >
                here.
              </a>{' '}
            </p>
          </div>
          <div className="faq__item faq--collapsed" onClick={ctaToggle}>
            <div className="faq__item--head">
              <div className="faq__item--number">03</div>
              <div className="faq__item--question">Who decides which projects get funded?</div>
              <Chevron className="faq__item--chevron" />
            </div>
            <p className="faq__item--answer">
              The community is who decides if a project gets funded or not. Technically, Only MANA, NAME or LAND holders
              may vote on proposals in the Decentraland DAO. The current minimum balance needed to have a weighted vote
              on proposals in the DAO is 1VP Voting with a balance of zero will result in a vote with a weight of 0,
              which does not impact the final results. Their vote will have the value of the MANA they have in their
              account. For more information you can check out the{' '}
              <a
                target="_blank"
                rel="noreferrer"
                onClick={onFaqLinkClick}
                href="https://docs.decentraland.org/player/general/dao/dao-userguide/#voting"
              >
                DAO User Guide.
              </a>
              <br />
              <br />
              Once you submit a grant proposal in our governance app, the community will have 14 days to vote if the
              grant should or should not receive the funding.
            </p>
          </div>
          <div className="faq__item faq--collapsed" onClick={ctaToggle}>
            <div className="faq__item--head">
              <div className="faq__item--number">04</div>
              <div className="faq__item--question">How much money is allocated to the program?</div>
              <Chevron className="faq__item--chevron" />
            </div>
            <div className="faq__item--answer">
              The Decentraland vesting contract allocates the DAO 1.8M MANA every month and it has set up a limit
              control to not spend more than 70% of such a stream in its grants program. This mitigates the risk of
              running out of funds, and securing the long-standing operations of the DAO. The Decentraland Grants
              Program is divided into four periods (Q1, Q2, Q3, Q4), effective since January 1st, 2023. At the beginning
              of each quarter the maximum amount of money that can be allocated through the Grants Program is defined
              using the formula: 5,500,000 * MANA_PRICE * 0.7. This means the DAO at maximum capacity will be spending
              70% of the MANA received from the vesting contract.
              <br />
              <br />
              The DAO agreed on a{' '}
              <a
                target="_blank"
                rel="noreferrer"
                onClick={onFaqLinkClick}
                href="https://decentraland.org/governance/proposal/?id=bfab7b70-7b75-11ed-ad27-015f26e7c35c"
              >
                governance proposal
              </a>{' '}
              on limits for each category of grants. The DAO refreshes and recalculates the category budgets using the
              MANA price at the beginning of each quarter. When a category reaches its budget, No more grant requests
              will be accepted for that category.
              <br />
              <br />
              The{' '}
              <a
                target="_blank"
                rel="noreferrer"
                onClick={onFaqLinkClick}
                href="https://github.com/Decentraland-DAO/transparency/blob/main/src/budgets.json"
              >
                allocation per category
              </a>{' '}
              is the following:
              <ul>
                <li>Platform 40%</li>
                <li>In-World Content 20%</li>
                <li>Core Unit 15%</li>
                <li>Sponsorship 10%</li>
                <li>Accelerator 7%</li>
                <li>Social Media Content 5%</li>
                <li>Documentation 3%</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="services__container">
        <img className="services__img" src={services} />
        <div className="services__cards">
          <div className="services__title">
            The grants
            <br />
            program offers
          </div>
          <div className="services__card">
            <div className="services__card--title">Grants</div>
            <div className="services__card--text">Up to 240k in your chosen roadmap duration</div>
          </div>
          <div className="services__card">
            <div className="services__card--title">Support</div>
            <div className="services__card--text">Consultancies and support on every step of the way</div>
          </div>
          <div className="services__card">
            <div className="services__card--title">Promotion</div>
            <div className="services__card--text">To showcase your project to the community</div>
          </div>
        </div>
      </div>
      <div className="reviews">
        <div className="reviews__title">What grantees say</div>
        <div className="reviews__container">
          <div className="reviews__item">
            <div className="reviews__item--body">
              {`We believe our game provides a unique experience apart from the other games as our gameplay is dynamically
              different. Our competitive seasons tend to activate even more activity as the prizes we give away are
              literal 1:1 unique and mythic wearables. We've also made a huge impact to the wearable economy.`}
            </div>
            <div className="reviews__item--footer">
              <div className="reviews__item--data">
                <div>Nikki Fuego</div>
                <div>Founder of Vroomway</div>
              </div>
              <img src={nf} alt="Nikki Fuego avatar" className="reviews__item--image" />
            </div>
          </div>
          <div className="reviews__item">
            <div className="reviews__item--body">
              The Community Grants program enabled our team to secure funding to develop and enhance tools that empower
              our users to make informed decisions. These tools provide an open and transparent information
              infrastructure for effective governance.
            </div>
            <div className="reviews__item--footer">
              <div className="reviews__item--data">
                <div>Gino Cingolani</div>
                <div>Governance Squad</div>
              </div>
              <img src={gc} alt="Gino Cingolani avatar" className="reviews__item--image" />
            </div>
          </div>
          <div className="reviews__item">
            <div className="reviews__item--body">
              Before DCL-Metrics, there was no easy way to get performance metrics about the Decentraland ecosystem or
              the individual scenes of which it is made. A grant from the DAO made this project possible and it
              continues to grow with the community, tracking the details of what Decentraland citizens find important.
            </div>
            <div className="reviews__item--footer">
              <div className="reviews__item--data">
                <div>DAX</div>
                <div>DCL Metrics</div>
              </div>
              <img src={dax} alt="DAX avatar" className="reviews__item--image" />
            </div>
          </div>
        </div>
        <a className="cta" target="_blank" rel="noreferrer" href="https://decentraland.org/governance/projects/">
          EXPLORE ALL PROJECTS
        </a>
      </div>
      <GrantsFooter />
    </>
  )
}
