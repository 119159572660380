function BlogIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g stroke="#FF2D55" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_8873_80)">
        <path d="M13.5 1H9M13.5 4H9M13.5 7H9M13.5 13H.5M13.5 10H.5M6 1H1a.5.5 0 00-.5.5v5A.5.5 0 001 7h5a.5.5 0 00.5-.5v-5A.5.5 0 006 1z"></path>
      </g>
      <defs>
        <clipPath id="clip0_8873_80">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default BlogIcon
