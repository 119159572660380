import { useQuery } from '@tanstack/react-query'

import { Governance, VpDistribution } from '../clients/Governance'

export const EMPTY_DISTRIBUTION: VpDistribution = {
  total: 0,
  own: 0,
  mana: 0,
  wMana: 0,
  land: 0,
  estate: 0,
  names: 0,
  delegated: 0,
  l1Wearables: 0,
  rental: 0,
}

export default function useVPDistribution(address: string) {
  const { data: vpDistribution, isLoading } = useQuery({
    queryKey: [`vpDistribution`, address],
    queryFn: async () => {
      if (!address) return EMPTY_DISTRIBUTION
      return await Governance.get().getVpDistribution(address)
    },
    staleTime: 3.6e6,
  })

  return { vpDistribution: vpDistribution ?? EMPTY_DISTRIBUTION, isLoadingVpDistribution: isLoading }
}
